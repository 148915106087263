<template>
    <div>
        <LiefengContent
            v-if="(!this.$route.query.columnCode && !this.$route.query.functionType && !(this.$route.query.id && this.$route.query.menuId && this.$route.query.status)) || this.$route.query.templateId"
        >
            <template v-slot:title>{{ menuCodeObj.columnName || "问卷采集类" }}</template>
            <template v-slot:toolsbarLeft>
                <Button type="primary" icon="ios-cube-outline" @click="$router.push({ path: '/formindex', query: { path: $route.path, menuId: $route.query.menuId } })" style="margin-right: 10px">
                    表单模板库
                </Button>
                <Button type="primary" icon="ios-cube-outline" @click="openNumCount" style="margin-right: 10px" v-if="buttonRoot == '1003'">行为数据分析</Button>
                <Button icon="ios-add" type="primary" v-if="buttonRoot == '1001'" @click="addQues">新增</Button>
            </template>
            <template v-slot:toolsbarRight>
                <Form :model="searchData" :label-colon="true" :inline="true" class="search">
                    <FormItem label="标题" :label-width="50" style="margin-bottom: 0">
                        <Input v-model="searchData.title" placeholder="请输入标题" style="width: 160px"></Input>
                    </FormItem>
                    <FormItem label="发布范围" :label-width="75">
                        <Cascader transfer v-model="searchData.dataScopeList" change-on-select :load-data="loadCommunity" :data="releaseScopeList"></Cascader>
                    </FormItem>
                    <FormItem label="拟制人" :label-width="60">
                        <Input v-model="searchData.orgName" style="width: 160px"></Input>
                    </FormItem>
                    <Button type="primary" icon="ios-search" @click="search" style="margin-right: 10px">查询</Button>
                    <Button type="success" @click="reset" icon="ios-refresh" style="margin-right: 10px">重置</Button>
                </Form>
            </template>
            <template v-slot:contentArea>
                <div class="table-left">
                    <Menu theme="light" :active-name="activeMenu" @on-select="selectMenu">
                        <MenuGroup title="信息状态">
                            <MenuItem name="2" v-if="buttonRoot == '1001'">待提交/已驳回({{ status2 }})</MenuItem>
                            <MenuItem name="1" v-if="buttonRoot == '1002'">待审核({{ status1 }})</MenuItem>
                            <MenuItem name="3" v-if="buttonRoot == '1003'">待发布({{ status3 }})</MenuItem>
                            <MenuItem name="4">
                                已发布
                                <Tooltip placement="right" content="已发布的内容居民在小程序、APP等终端可正常查看到。"><Icon type="ios-help-circle-outline" size="20" /></Tooltip>
                            </MenuItem>
                            <MenuItem name="5">
                                已过期
                                <Tooltip placement="right" content="到了截止时间内容则为已过期，在小程序的全部和往期回顾中可查看到。"><Icon type="ios-help-circle-outline" size="20" /></Tooltip>
                            </MenuItem>
                            <MenuItem name="6">
                                已归档
                                <Tooltip placement="right" content="归档后的内容在小程序、APP等终端则不显示，对用户不可见。"><Icon type="ios-help-circle-outline" size="20" /></Tooltip>
                            </MenuItem>
                            <MenuItem name="7">全部信息</MenuItem>
                        </MenuGroup>
                    </Menu>
                </div>
                <LiefengTable
                    :talbeColumns="talbeColumns"
                    :tableData="tableData"
                    :loading="loading"
                    :fixTable="true"
                    :curPage="page"
                    :total="total"
                    :pagesizeOpts="[20, 30, 50, 100]"
                    :page-size="pageSize"
                    @hadlePageSize="hadlePageSize"
                    @tableSelect="tableSelect"
                ></LiefengTable>
            </template>
        </LiefengContent>
        <!-- 新增修改 -->
        <LiefengModal :title="addChangeTitle" :value="addChangeStatus" @input="addChangeStatusFn" :fullscreen="true">
            <template v-slot:contentarea>
                <AddChange
                    :buttonRoot="buttonRoot"
                    v-if="addChangeStatus"
                    :activeId="activeId"
                    :addChangeIden="addChangeIden"
                    @saveSuccess="saveSuccess"
                    :menuCodeObj="menuCodeObj"
                    ref="addChange"
                    :formData="formData"
                    :addType="addType"
                    :quoteNum="quoteNum"
                ></AddChange>
            </template>
            <template v-slot:toolsbar>
                <Button
                    type="info"
                    @click="
                        cancelModalTip = true
                        addChangeStatusFn(false)
                    "
                    style="margin-right: 10px"
                >
                    取消
                </Button>
                <Button type="primary" :loading="saveLoading" @click="save">保存</Button>
                <!--        <Button type="warning" style="margin-left: 10px" @click="contentFn">预览文本</Button>-->
            </template>
        </LiefengModal>
        <!-- 审核/发布 -->
        <LiefengModal :title="publishTitle" :value="publishStatus" @input="publishStatusFn" :fullscreen="true">
            <template v-slot:contentarea>
                <Publish
                    :buttonRoot="buttonRoot"
                    v-if="publishStatus"
                    :activeId="activeId"
                    :infoStatus="infoStatus"
                    @saveSuccess="saveSuccess"
                    :menuCodeObj="menuCodeObj"
                    :publicRecommend="publicRecommend"
                    ref="publish"
                    :orgCode="orgCode"
                ></Publish>
            </template>
            <template v-slot:toolsbar>
                <Button type="info" @click="publishStatusFn(false)" style="margin-right: 10px">取消</Button>
                <Button type="warning" @click="reject" style="margin-right: 10px" v-if="infoStatus == '待审核' || infoStatus == '待发布'">驳回</Button>
                <Button type="success" @click="publish">
                    {{ publishButtonName }}
                </Button>
                <Button type="warning" style="margin-left: 10px" v-if="infoStatus == '待审核' && buttonRoot == '1002'" @click="passAndSend">通过并加急发布</Button>
            </template>
        </LiefengModal>
        <!-- 查看 -->
        <LiefengModal title="查看问卷" :value="seeStatus" @input="seeStatusFn" :fullscreen="true">
            <template v-slot:contentarea>
                <Publish :buttonRoot="buttonRoot" ref="publish1" v-if="seeStatus" :activeId="activeId" :menuCodeObj="menuCodeObj"></Publish>
            </template>
            <template v-slot:toolsbar>
                <Button type="info" :style="{ marginRight: buttonRoot == '1001' ? '10px' : '' }" @click="seeStatusFn(false)">关闭</Button>
                <Button type="primary" :style="{ display: buttonRoot == '1001' ? '' : 'none' }" @click="seeToChange">修改</Button>
                <!--        <Button type="warning" style="margin-left: 10px" @click="contentFn1">预览文本</Button>-->
            </template>
        </LiefengModal>
        <!-- 公众号链接 -->
        <LiefengModal title="分享并预览" :value="officialStatus" @input="officialStatusFn" width="1146px" height="calc(100vh - 100px)" class="signInOut">
            <template v-slot:contentarea>
                <div>
                    <Select v-model="officialCity" transfer style="width: 140px" placeholder="请选择市" filterable @on-change="val => getOfficialScopeList(signInObj.id, '3', val)">
                        <Option :value="item.orgCode" v-for="(item, index) in officialCityList" :key="index">{{ item.orgName }}</Option>
                    </Select>
                    <Select
                        v-model="officialRegion"
                        transfer
                        style="width: 140px"
                        placeholder="请选择区"
                        filterable
                        @on-clear="clearRegion"
                        clearable
                        @on-change="val => getOfficialScopeList(signInObj.id, '4', val)"
                    >
                        <Option :value="item.orgCode" v-for="(item, index) in officialRegionList" :key="index">{{ item.orgName }}</Option>
                    </Select>
                    <Select
                        clearable
                        v-model="officialStree"
                        transfer
                        style="width: 140px"
                        placeholder="请选择街道/乡镇"
                        filterable
                        @on-clear="clearStree"
                        @on-change="val => getOfficialScopeList(signInObj.id, '5', val)"
                    >
                        <Option :value="item.orgCode" v-for="(item, index) in officialStreeList" :key="index">{{ item.orgName }}</Option>
                    </Select>
                    <Select v-model="officialProject" transfer :label-in-value="true" style="width: 270px" :max-tag-count="2" placeholder="请选择社区" multiple filterable @on-change="changeProject">
                        <Option :value="item.orgCode" v-for="(item, index) in officialProjectList" :key="index">{{ item.orgName }}</Option>
                    </Select>
                    <Button type="primary" style="margin: 0 10px" @click="makeOfficialQRCode">生成链接</Button>
                    <!-- <Button type="info" :disabled="makeOffDisabled" style="margin:0 10px" @click="makeOfficialQRcodeList">按社区生成分享码</Button> -->
                    <Poptip placement="bottom-end" word-wrap width="500">
                        <Icon type="md-help" />
                        <div slot="content" style="text-indent: 2em">
                            <p>说明:</p>
                            <p>生成分享码: 是将选择的当前范围生成一个二维码进行推广分享(一张)</p>
                            <p>按社区生成分享码:是将选择的当前范围下包含的所有社区按照社区为</p>
                            <p>单位生成各自的社区二维码进行推广分享(多张)</p>
                            <p>二维码的样式与下载时选择的模板一致</p>
                        </div>
                    </Poptip>
                </div>
                <div v-if="codeImgUrl && officialUrl && showMake != true && linkMake == true">
                    <h3 style="margin-top: 10px">该链接可以用于配置到公众号进行推广:</h3>
                    <div style="display: flex; justify-content: space-between; line-height: 32px; margin: 14px auto">
                        <span>公众号链接：</span>
                        <Input v-model="officialUrl" search enter-button="复制" style="flex: 1; margin-right: 100px" @on-search="copyShareCode"></Input>
                    </div>
                    <div style="display: flex; justify-content: space-between; line-height: 32px; margin: 14px auto">
                        <span>渠道ID：</span>
                        <Input v-model="officialID" search enter-button="复制" style="flex: 1; margin-right: 100px" @on-search="copyOfficialID"></Input>
                    </div>
                    <!-- <div
            style="
              display: flex;
              justify-content: space-between;
              line-height: 32px;
              margin: 14px auto;
            "
          >
            <span style="width:84px">短信链接：</span>
            <Input
              v-model="noteUrl"
              search
              enter-button="复制"
              style="flex: 1;margin-right: 100px"
              @on-search="copyNoteUrl"
            ></Input>
          </div> -->
                    <h3>推广二维码已生成，可下载后直接分享二维码到微信（朋友、朋友圈）</h3>
                    <div class="qrdiv">
                        <div style="display: flex; align-items: center; justify-content: center; margin-right: 200px">
                            <div :class="!textActive ? 'qrcode qrcode-antoer' : 'qrcode'" ref="haibaoImg" :style="{ background: `url('${qrimg}') no-repeat` }">
                                <div class="h1h3">
                                    <!-- <div class="h1h3 fontsize3">
                  <div> -->
                                    <div
                                        :class="
                                            qrOCdeName.length <= 8
                                                ? !textActive
                                                    ? 'fontsize1 fontactive'
                                                    : 'fontsize1'
                                                : qrOCdeName.length > 8 && qrOCdeName.length <= 30
                                                ? !textActive
                                                    ? 'fontsize2 fontactive'
                                                    : 'fontsize2'
                                                : !textActive
                                                ? 'fontsize3 fontactive'
                                                : 'fontsize3'
                                        "
                                    >
                                        {{ qrOCdeName }}
                                    </div>

                                    <!-- <h3>入驻{{qrOCdeName}}</h3> -->
                                </div>
                                <!-- qrOCdeName -->
                                <span style="letter-spacing: 3px; z-index: 99">{{ qrCodeOrgName }}</span>
                                <img :src="codeImgUrl + '?' + new Date().getTime()" alt ref="qrCode" crossorigin="anonymous" style="z-index: 98" />
                            </div>
                            <div style="width: 130px; height: 100%; margin-left: 30px; margin-bottom: 480px">
                                <Button type="primary" style="margin-bottom: 20px; width: 130px" @click="downloadImgCode">下载纯二维码</Button>
                                <Button type="warning" @click="mergeImage">下载宣传二维码</Button>
                            </div>
                            <div :class="!textActive ? 'diyimg' : 'diyimg diyimg-right'">
                                <Progress v-if="file.showProgress" :percent="file.percentage" :stroke-width="5"></Progress>
                                <Upload
                                    ref="imgUpload"
                                    :before-upload="beforeUpload"
                                    :format="['jpg', 'jpeg', 'png', 'gif']"
                                    style="display: inline-block"
                                    :on-format-error="imageError"
                                    :on-progress="imageProgress"
                                    :on-success="(value, file) => imageSuccess(file)"
                                    :action="action"
                                    :data="uploadData"
                                    :on-remove="imageRemove"
                                    :show-upload-list="false"
                                >
                                    <Button icon="ios-cloud-upload-outline" type="info">自定义更换背景</Button>
                                </Upload>
                                <Button v-if="qrimg" type="success" @click="cropperFn" style="margin-left: 10px">裁剪图片</Button>
                                <div class="checkimg">
                                    <div class="imglist">
                                        <img @click="changeQrimgNo(item, index)" :class="qrimgNo == index + 1 ? 'active' : ''" v-for="(item, index) in qrimgList" :key="item" :src="item" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <img :src="codeImgUrl" alt width="200" style="display: block;margin-bottom: 10px" />
            <Button type="primary" @click="downloadImgCode">下载二维码</Button> -->
                    </div>
                </div>
                <div
                    v-if="!codeImgUrl && !officialUrl && linkMake == false && showMake == false"
                    style="
                        height: calc(100vh - 305px);
                        overflow: auto;
                        margin: 20px;
                        border-radius: 4px;
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background: #ccc;
                        color: #fff;
                        font-size: 20px;
                    "
                >
                    可生成街道或者社区的推广链接和二维码
                </div>

                <div v-if="showMake == true && linkMake != true">
                    <h3>推广二维码已生成，可下载后直接分享二维码到微信（朋友、朋友圈）</h3>
                    <div class="qrdiv">
                        <div style="display: flex; align-items: center; justify-content: center; margin-right: 200px">
                            <div class="qrcode" ref="refAllImage">
                                <div class="h1h3">
                                    <h1>{{ qrOCdeName | spliceTitle }}</h1>
                                </div>
                                <p>微信扫码查看详情服务</p>
                                <img class="qrdiv-image-div" crossorigin="anonymous" src="https://property-liefeng.oss-cn-shenzhen.aliyuncs.com/TEST/zjsm/20220113/20220113165945pOnBHwAz.png" />
                            </div>
                            <div style="width: 130px; height: 100%; margin-left: 30px">
                                <Button type="warning" :disabled="isDwonLoad" @click="downAllImage">下载宣传二维码</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </LiefengModal>
        <!-- 相关链接 -->
        <LiefengModal title="相关链接" :value="aboutLinkStatus" @input="aboutLinkStatusFn" :fullscreen="true">
            <template v-slot:contentarea>
                <AboutLink ref="aboutLink" @saveSuccess="saveSuccess" v-if="aboutLinkStatus" :menuCodeObj="menuCodeObj" :infoId="infoId" :infoCode="infoCode"></AboutLink>
            </template>
            <template v-slot:toolsbar>
                <Button
                    type="info"
                    @click="
                        cancelModalTip = true
                        aboutLinkStatusFn(false)
                    "
                    style="margin-right: 10px"
                >
                    取消
                </Button>
                <Button type="primary" @click="aboutLinkSave">保存</Button>
            </template>
        </LiefengModal>
        <!-- zhw新增 -->
        <!--更换栏目-->
        <LiefengModal title="更换栏目" :value="ChangeColumn" @input="ChangeColumnFn" :fullscreen="false">
            <template v-slot:contentarea>
                <div style="height: 200px">
                    <Cascader style="width: 50%; padding-top: 80px; margin-left: 120px" :data="cascaderData" v-model="cascadValue"></Cascader>
                </div>
            </template>
            <template v-slot:toolsbar>
                <Button
                    type="info"
                    @click="
                        cancelModelTip = true
                        ChangeColumnFn(false)
                    "
                    style="margin-right: 10px"
                >
                    取消
                </Button>
                <Button type="primary" @click="changeSave">保存</Button>
            </template>
        </LiefengModal>
        <!--用户操作记录-->
        <LiefengModal title="用户操作记录" :value="userChooseTle" @input="UserChooseFn" :fullscreen="true">
            <template v-slot:contentarea>
                <CheckRecord :businessType="sendMessage.businessType" :infoId="sendMessage.infoId" :isShow="isOpenUserMessage" @userSave="userSave"></CheckRecord>
            </template>
        </LiefengModal>
        <!--行为数据分析-->
        <LiefengModal title="行为数据分析" :value="numFenxiStatus" @input="numFenxiFn" :fullscreen="true">
            <template v-slot:contentarea>
                <Analysis :isTrue="isTrue" :infoIdLis="infoIdLis"></Analysis>
            </template>
            <template v-slot:toolsbar>
                <Button type="primary" @click="numFenxiFn(false)">关闭</Button>
            </template>
        </LiefengModal>
        <!-- 导出弹窗 -->
        <LiefengModal title="导出" :value="exportExcelStatus" @input="exportExcelStatusFn" :fullscreen="false">
            <template v-slot:contentarea>
                <div style="height: 300px">
                    <Form :label-colon="true" :label-width="100" style="padding-right: 20px">
                        <FormItem label="所属社区">
                            <Select v-model="marital" placeholder="请选择社区" filterable @on-change="selectCommunity">
                                <Option v-for="(item, index) in communityList" :key="index" :value="item.code">{{ item.fullName }}</Option>
                            </Select>
                        </FormItem>
                    </Form>
                </div>
            </template>
            <template v-slot:toolsbar>
                <Button
                    type="info"
                    style="margin-right: 10px"
                    @click="
                        exportExcelTip = true
                        exportExcelStatusFn(false)
                    "
                >
                    取消
                </Button>
                <Button type="primary" @click="canfirmExport">确认导出</Button>
            </template>
        </LiefengModal>

        <!--对比分析-->
        <LiefengModal title="对比分析" :value="contrastStatus" @input="contrastFn" :fullscreen="true">
            <template v-slot:contentarea>
                <contrast :infoId="contrastInfoId" :functionType="conFunctionType" :businessType="conBusinessType" :isShow="contrastShow"></contrast>
            </template>
            <template v-slot:toolsbar>
                <Button type="primary" @click="contrastClose">返回</Button>
            </template>
        </LiefengModal>

        <!-- 模板 -->
        <LiefengModal :title="`${menuCodeObj.columnName}新增` || '问卷采集类新增'" :value="templateStatus" @input="changeTemplateStatus" width="1200px" height="625px">
            <template v-slot:contentarea>
                <Templatetable
                    @getActiveId="activeId"
                    @changeAddType="changeAddType"
                    ref="Templatetable"
                    :columnCode="columnCode"
                    @addChangeStatusFn="addChangeStatusFn"
                    @setFormData="setFormData"
                    @quoteData="quoteData"
                ></Templatetable>
            </template>
            <template v-slot:toolsbar>
                <Button style="margin-right: 10px" type="primary" @click="changeTemplateStatus(false)">关闭</Button>
            </template>
        </LiefengModal>
        <!-- 关联数据 -->
        <LiefengModal title="关联数据" :value="relationStatus" @input="changeRelationStatus" :fullscreen="true">
            <template v-slot:contentarea>
                <Relation ref="relation" :quesDate="quesDate" :showNum="showNum"></Relation>
            </template>
            <template v-slot:toolsbar>
                <Button style="margin-right: 10px" type="primary" @click="changeRelationStatus(false)">关闭</Button>
            </template>
        </LiefengModal>
        <!-- 分享二维码背景图片裁剪弹窗 -->
        <LiefengModal @input="cropperCancel" width="600px" height="500px" :value="cropperStatus">
            <template v-slot:contentarea>
                <!-- <Mymodel
            :fullscreen="false"
            :value="toggle1"
            :toggle="true"
            :textObj="textObj"
            title="文本预览"
            :toggle-fn2="toggleFn2"
        >
        </Mymodel> -->
                <div class="cropper-content" v-if="cropperStatus">
                    <div class="cropper" style="text-align: center">
                        <vueCropper
                            ref="cropper"
                            :img="option.img"
                            :outputSize="option.size"
                            :outputType="option.outputType"
                            :info="true"
                            :full="option.full"
                            :canMove="option.canMove"
                            :canMoveBox="option.canMoveBox"
                            :original="option.original"
                            :autoCrop="option.autoCrop"
                            :fixed="option.fixed"
                            :fixedNumber="option.fixedNumber"
                            :centerBox="option.centerBox"
                            :infoTrue="option.infoTrue"
                            :fixedBox="option.fixedBox"
                        ></vueCropper>
                    </div>
                </div>
            </template>
            <template v-slot:toolsbar>
                <Button type="info" @click="cropperCancel(false)" style="margin-right: 10px">取消</Button>
                <Button type="primary" @click="cropperFinish">裁剪</Button>
            </template>
        </LiefengModal>

        <!-- 推送 -->
        <LiefengModal title="推送" :value="pushStatus" @input="changePushStatus" width="400px">
            <template v-slot:contentarea>
                <Form>
                    <FormItem>
                        <span slot="label" class="validate">机构名称</span>
                        <Input style="width: 300px" v-model="pushObj.orgName" :maxlength="20" disabled placeholder="机构名称"></Input>
                    </FormItem>
                    <FormItem>
                        <span slot="label" class="validate">通知内容</span>
                        <Input style="width: 300px" v-model="pushObj.title" :maxlength="20" placeholder="请输入通知内容"></Input>
                    </FormItem>
                </Form>
            </template>
            <template v-slot:toolsbar>
                <Button style="margin-right: 10px" type="primary" @click="customPush">推送</Button>
                <Button style="margin-right: 10px" type="primary" @click="changePushStatus(false)">关闭</Button>
            </template>
        </LiefengModal>

        <LiefengModal title="更换栏目" :value="synchroniZationStatus" @input="synchroniZationStatusFn" :fullscreen="false">
            <template v-slot:contentarea>
                <Synchron v-if="synchroniZationStatus" :infoId="synchronId" :functionType="menuCodeObj.functionType" ref="Synchron"></Synchron>
            </template>
            <template v-slot:toolsbar>
                <Button
                    type="info"
                    @click="
                        synchroniZationStatusTip = true
                        synchroniZationStatusFn(false)
                    "
                    style="margin-right: 10px"
                >
                    取消
                </Button>
                <Button type="primary" @click="saveSynchroniZation">保存</Button>
            </template>
        </LiefengModal>

        <!-- 操作模态框 -->
        <LiefengModal class="caozuo-modal" title="操作" :value="caozuoStatus" width="800" @input="caozuoStatusFn">
            <template #contentarea>
                <div class="content-div">
                    <div class="module-item">
                        <div class="title">核心数据</div>
                        <div class="import-div">
                            <div class="left" v-if="dimensionId == 2">
                                <div class="num">
                                    <span class="blue">{{ caozuoInfo.registerViewUser || 0 }}</span>
                                    |
                                    <span class="none">{{ caozuoInfo.registerUser || 0 }}</span>
                                    <span class="red">{{ caozuoInfo.registerUserScale || 0 }}</span>
                                </div>
                                <div class="desc">整体阅读情况</div>
                            </div>
                            <div class="right" style="position: relative">
                                <div class="num">
                                    <span class="blue">{{ caozuoInfo.weixinMemberView || 0 }}</span>
                                    |
                                    <span class="none">{{ caozuoInfo.weixinMember || 0 }}</span>
                                    <span class="red">{{ caozuoInfo.weixinMemberScale || 0 }}</span>
                                </div>
                                <div class="desc">微信群用户阅读情况</div>

                                <div style="position: absolute; top: 5px; right: 10px; color: blue" @click="changeMore(caozuoInfo.id)">详情</div>
                            </div>
                            <div class="text">
                                <p>整体阅读情况：全部阅读量与发布数据时的注册用户的占比，重要信息要求阅读量大于60%</p>
                                <p>微信群用户阅读情况：通过分享到微信群的信息，点击查看的阅读人数与微信群总人数的占比</p>
                            </div>
                        </div>
                    </div>
                    <div class="module-item">
                        <div class="title">重要功能</div>
                        <div class="fun-btns">
                            <div class="btn-box" v-for="(item, index) in caozuoList" :key="index" @click="changeItem(item)">{{ item.name }}</div>
                        </div>
                    </div>
                    <div class="module-item" v-if="caojiList && caojiList.length">
                        <div class="title">采集信息</div>
                        <div class="fun-btns">
                            <div class="btn-box" v-for="(item, index) in caojiList" :key="index" @click="changeItem(item)">{{ item.name }}</div>
                        </div>
                    </div>
                    <div class="module-item">
                        <div class="title">推送通知</div>
                        <div class="fun-btns">
                            <div class="btn-box" @click="changeOpenSendModal">{{ "精准推送" }}</div>
                            <div class="btn-box" @click="jumpSendDetail">{{ "推送记录" }}</div>
                        </div>
                    </div>
                    <div class="module-item">
                        <div class="title">内容设置</div>
                        <div class="set-content">
                            <Form :label-colon="true" :label-width="120">
                                <FormItem label="发布终端">
                                    <span slot="label">发布终端:</span>
                                    <Checkbox
                                        @on-change="
                                            val => {
                                                changeCheck(val, item)
                                            }
                                        "
                                        v-model="item.select"
                                        v-for="(item, index) in channelList"
                                        :key="index"
                                        :label="item.label"
                                    >
                                        {{ item.value }}
                                    </Checkbox>
                                </FormItem>
                                <FormItem>
                                    <span slot="label">推荐到首页:</span>
                                    <RadioGroup v-model="setContent.recommend">
                                        <Radio label="0">否</Radio>
                                        <Radio label="1">是</Radio>
                                    </RadioGroup>
                                </FormItem>
                                <FormItem label="联系电话">
                                    <Input v-model.trim="setContent.contactMobile" style="width: 200px" :maxlength="20"></Input>
                                </FormItem>
                                <!-- <FormItem label="小程序推送">
                                    <CheckboxGroup v-model="setContent.pushType" @on-change="changePushType">
                                        <Checkbox label="1">我的用户</Checkbox>
                                        <span style="color: #aaa; width: 200px; margin-left: 16px">注：勾选后，订阅了社区通知的服务过的居民可收到推送信息。</span>
                                        <br />
                                        <Checkbox label="2">我的管理员</Checkbox>
                                        <span style="color: #aaa; width: 200px; margin-left: 16px">注：勾选后，订阅了社区通知的员工档案在职管理员可收到推送信息。</span>
                                        <br />
                                        <Checkbox label="3">我的畅联组</Checkbox>
                                        <span v-if="setContent.pushType && setContent.pushType[0] == 3">
                                            <Input style="width: 300px; margin-left: 16px" v-model="setContent.groupNames" />
                                            <Button type="primary" @click="groupStatus = true">选群</Button>
                                        </span>
                                        <br />
                                        <Checkbox label="4" v-if="dimensionId == 2 || buttonRoot == '1003'">社区线上用户</Checkbox>
                                        <span v-if="dimensionId == 2 || buttonRoot == '1003'" style="color: #aaa; width: 200px; margin-left: 16px">注：勾选后，订阅了社区通知的所有线上居民可收到推送信息。</span>
                                    </CheckboxGroup>
                                </FormItem> -->
                            </Form>
                        </div>
                    </div>
                    <div class="module-item">
                        <div class="title">提交成功图片设置</div>
                        <div class="set-content">
                            <Form :label-colon="true" :label-width="120">
                                <FormItem>
                                    <span slot="label">提示图片:</span>
                                    <LiefengUpload
                                        ref="LiefengUploadIcon"
                                        v-if="showImage"
                                        accept=".jpg,.png,.gif,.jpeg"
                                        :format="['jpg', 'png', 'jpeg', 'gif']"
                                        :defaultList="defaultListIcon"
                                        :showView="true"
                                    ></LiefengUpload>
                                    <span style="color: #aaa; width: 200px; margin-left: 16px">比如:邀请报名的人进群，可以上传群二维码</span>
                                </FormItem>
                                <FormItem>
                                    <span slot="label">提示说明:</span>
                                    <Input v-model.trim="imageDesc" placeholder="如：点击图片，长按识别二维码进群" style="width: 200px"></Input>
                                </FormItem>
                            </Form>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:toolsbar>
                <Button type="info" style="margin-right: 20px" @click="caozuoStatusFn(false)">取消</Button>
                <Button type="primary" @click="setSave">确定</Button>
            </template>
        </LiefengModal>
        <!-- 推送选群 -->
        <LiefengModal :value="groupStatus" title="选择畅联组" :fullscreen="true" @input="changeGroupStatus">
            <template v-slot:contentarea>
                <CheckGroup :buttonRoot="buttonRoot" ref="checkGroup" @getGroup="getGroup"></CheckGroup>
            </template>
            <template v-slot:toolsbar>
                <!-- <Button type="info" @click="cancelGroup" style="margin-right:10px"> 取消 </Button> -->
                <Button type="primary" @click="saveGroup">保存</Button>
            </template>
        </LiefengModal>

        <!-- 推送选群 -->
        <LiefengModal :value="sendGroupStatus" title="推送对象设置" :fullscreen="true" @input="changeGroupStatus2">
            <template v-slot:contentarea>
                <Form :label-colon="true" :label-width="120">
                    <FormItem label="推送对象设置">
                        <RadioGroup v-model="sendGroup.pushChannel" @on-change="changePushChannel">
                            <Radio label="1">我的用户</Radio>
                            <span style="color: #aaa; width: 200px; margin-left: 16px">注：勾选后，订阅了社区通知的服务过的居民可收到推送信息。</span>
                            <span style="color: #19be6b; margin-left: 10px">{{ coreData.userNum }}人</span>
                            <br />
                            <Radio label="2">我的管理员</Radio>
                            <span style="color: #aaa; width: 200px; margin-left: 16px">注：勾选后，订阅了社区通知的员工档案在职管理员可收到推送信息。</span>
                            <span style="color: #19be6b; margin-left: 10px">{{ coreData.custNum }}人</span>
                            <br />
                            <Radio label="3">我的畅联组</Radio>
                            <span style="color: #aaa; width: 200px; margin-left: 16px">注：选择群后，群成员中订阅了社区通知的用户均可收到推送信息。</span>
                            <br />
                            <Radio label="5" v-if="dimensionId == 2 || buttonRoot == '1003'">社区注册用户</Radio>
                            <span v-if="dimensionId == 2 || buttonRoot == '1003'" style="color: #aaa; width: 200px; margin-left: 16px">
                                注：勾选后，订阅了社区通知且 激活了社区（村）地址的居民用户可收到推送信息。
                            </span>
                            <span v-if="dimensionId == 2 || buttonRoot == '1003'" style="color: #19be6b; margin-left: 10px">{{ coreData.registerNum }}人</span>
                            <br v-if="dimensionId == 2 || buttonRoot == '1003'" />
                            <Radio label="4" v-if="dimensionId == 2 || buttonRoot == '1003'">社区访问用户</Radio>
                            <span v-if="dimensionId == 2 || buttonRoot == '1003'" style="color: #aaa; width: 200px; margin-left: 16px">
                                注：勾选后，订阅了社区通知的所有访问过本社区的用户可收到推送信息。
                            </span>
                            <span v-if="dimensionId == 2 || buttonRoot == '1003'" style="color: #19be6b; margin-left: 10px">{{ coreData.viewNum }}人</span>
                            <br v-if="dimensionId == 2 || buttonRoot == '1003'" />

                            <Radio label="7" v-if="dimensionId == 2 || buttonRoot == '1003'">指定居民数据库人群</Radio>
                            <span v-if="dimensionId == 2 || buttonRoot == '1003'" style="color: #aaa; width: 200px; margin-left: 16px">
                                注：勾选后，订阅了社区通知且激活了账号的居民数据库报表人群可收到推送信息。
                            </span>
                            <br v-if="dimensionId == 2 || buttonRoot == '1003'" />

                            <Radio label="10">数采服务系统报表</Radio>
                            <span style="color: #aaa; width: 200px; margin-left: 16px">注：勾选后，订阅了社区通知且激活了账号的数采服务系统报表人员可收到推送信息。</span>
                            <br />
                        </RadioGroup>
                    </FormItem>
                </Form>
                <div class="container-div" v-show="sendGroup.pushChannel && (sendGroup.pushChannel == '3' || sendGroup.pushChannel == '7' || sendGroup.pushChannel == '10')">
                    <div class="center-table">
                        <div class="table">
                            <LiefengTable
                                :talbeColumns="grouptalbeColumns"
                                :tableData="grouptableData"
                                :loading="grouploading"
                                :fixTable="true"
                                :curPage="grouppage"
                                :total="grouptotal"
                                :pagesizeOpts="[20, 30, 50, 100]"
                                :page-size="grouppageSize"
                                @hadlePageSize="grouphadlePageSize"
                                @tableSelect="grouptableSelect"
                                class="group-table"
                                ref="lftable"
                                :hidePage="sendGroup.pushChannel && sendGroup.pushChannel == '7'"
                            ></LiefengTable>
                        </div>
                    </div>
                    <div class="right-table">
                        <div class="right-center">
                            <Button type="primary" style="margin-bottom: 14px" @click="pushToRightList">右移</Button>
                            <Button type="error" style="margin-bottom: 14px" @click="delRightList">删除</Button>
                            <Button type="warning" @click="clearRightList">清空</Button>
                        </div>

                        <div class="right-right">
                            <div style="text-align: center; font-size: 14px; font-weight: bold; line-height: 40px; color: #515a6e; background: #f8f8f9">已选择</div>
                            <List border size="small">
                                <ListItem v-for="(item, index) in rightList" :key="item.id">
                                    <div style="width: 100%; display: block; cursor: pointer" @click="selectRightList($event, index)">
                                        {{ item.groupName }}
                                    </div>
                                </ListItem>
                            </List>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:toolsbar>
                <Button type="info" @click="changeGroupStatus2(false)" style="margin-right: 10px">取消</Button>
                <Button type="primary" @click="saveSendGroup">精准推送</Button>
            </template>
        </LiefengModal>
    </div>
</template>

<script>
//@route('/questionnaireindex')
import LiefengContent from "@/components/LiefengContent3"
import LiefengModal from "@/components/LiefengModal"
import LiefengTable from "@/components/LiefengTable"
import AddChange from "@/views/questionnaire/childrens/addChange"
import Publish from "@/views/questionnaire/childrens/publish"
import AboutLink from "@/views/questionnaire/childrens/aboutLink"
import CheckRecord from "@/views/questionnaire/childrens/checkRecord"
import Analysis from "@/views/questionnaire/childrens/analysis"
import contrast from "@/views/questionnaire/childrens/contrast"
import Templatetable from "@/views/questionnaire/childrens/templatetable"
import Relation from "@/views/questionnaire/childrens/relationdata"
import html2canvas from "html2canvas"
import { qrcodeMixin } from "./mixin/qrcodeMixin.js"
import Synchron from "@/views/activity/childrens/synchron"
import CheckGroup from "@/views/questionnaire/childrens/checkgroup"
import LiefengUpload from "@/components/LiefengUpload"
import { format } from "@/utils/formatTime.js"
import proxy from "@/api/proxy"

export default {
    mixins: [qrcodeMixin],
    data() {
        return {
            searchData: { dataScopeList: [] },
            // 发布范围
            releaseScopeList: [],
            talbeColumns: [
                {
                    type: "selection",
                    width: 60,
                    align: "center",
                },
                {
                    title: "标题",
                    key: "title",
                    minWidth: 200,
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: {
                                    color: "#2d8cf0",
                                    cursor: "pointer",
                                    userSelect: "none",
                                    textAlign: "center",
                                },
                                on: {
                                    click: () => {
                                        this.activeId = params.row.id
                                        this.seeStatus = true
                                    },
                                },
                            },
                            params.row.title
                        )
                    },
                },

                {
                    title: "发布范围",
                    key: "releaseScope",
                    width: 180,
                    align: "center",
                },
                {
                    title: "公布时效",
                    key: "startTime",
                    width: 200,
                    align: "center",
                    render: (h, params) => {
                        return h("div", {}, params.row.startTime + " 到 " + params.row.endTime)
                    },
                },
                {
                    title: "实名认证",
                    key: "authentication",
                    width: 100,
                    align: "center",
                },
                {
                    title: "拟制人",
                    key: "createName",
                    width: 100,
                    align: "center",
                },
                {
                    title: "推荐",
                    key: "recommend",
                    width: 165,
                    align: "center",
                    render: (h, params) => {
                        return (
                            "div",
                            [
                                h(
                                    "RadioGroup",
                                    {
                                        attrs: {
                                            value: params.row.recommend,
                                        },
                                        on: {
                                            "on-change": status => {
                                                let data = {
                                                    id: params.row.id,
                                                    recommend: status,
                                                    sort: params.row.sort,
                                                    functionType: params.row.functionType,
                                                    status:
                                                        params.row.status.indexOf("待审核") > -1
                                                            ? "1"
                                                            : params.row.status.indexOf("审核不通过") > -1
                                                            ? "2"
                                                            : params.row.status.indexOf("待发布") > -1
                                                            ? "3"
                                                            : params.row.status.indexOf("已发布") > -1
                                                            ? "4"
                                                            : params.row.status.indexOf("已过期") > -1
                                                            ? "5"
                                                            : params.row.status.indexOf("已归档") > -1
                                                            ? "6"
                                                            : "",
                                                    terminal: params.row.terminal,
                                                }
                                                this.changeStatusRow(data)
                                            },
                                        },
                                    },
                                    [
                                        h(
                                            "Radio",
                                            {
                                                attrs: {
                                                    label: "1",
                                                    disabled: params.row.status == "已发布" || params.row.status == "已过期" ? false : true,
                                                },
                                            },
                                            "推荐"
                                        ),
                                        h(
                                            "Radio",
                                            {
                                                attrs: {
                                                    label: "0",
                                                    disabled: params.row.status == "已发布" || params.row.status == "已过期" ? false : true,
                                                },
                                            },
                                            "不推荐"
                                        ),
                                    ]
                                ),
                            ]
                        )
                    },
                },
                {
                    title: "排序",
                    key: "sort",
                    width: 110,
                    align: "center",
                    render: (h, params) => {
                        return h("InputNumber", {
                            attrs: {
                                min: 1,
                                max: 999999999,
                                value: params.row.sort,
                                precision: 0,
                            },
                            style: {
                                width: "100%",
                            },
                            on: {
                                "on-change": val => {
                                    if (val == params.row.sort) return
                                    let data = {
                                        id: params.row.id,
                                        recommend: params.row.recommend,
                                        sort: val,
                                        status:
                                            params.row.status.indexOf("待审核") > -1
                                                ? "1"
                                                : params.row.status.indexOf("审核不通过") > -1
                                                ? "2"
                                                : params.row.status.indexOf("待发布") > -1
                                                ? "3"
                                                : params.row.status.indexOf("已发布") > -1
                                                ? "4"
                                                : params.row.status.indexOf("已过期") > -1
                                                ? "5"
                                                : params.row.status.indexOf("已归档") > -1
                                                ? "6"
                                                : "",
                                        functionType: params.row.functionType,
                                        terminal: params.row.terminal,
                                    }
                                    clearTimeout(this.timer)
                                    this.timer = setTimeout(() => {
                                        this.changeStatusRow(data)
                                    }, 1000)
                                },
                            },
                        })
                    },
                },
                {
                    title: "审核状态",
                    key: "status",
                    width: 120,
                    align: "center",
                },
                {
                    title: "编码",
                    key: "code",
                    width: 100,
                    align: "center",
                },
                {
                    title: "操作",
                    align: "center",
                    width: 180,
                    fixed: "right",
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: {
                                    textAlign: "center",
                                },
                            },
                            [
                                h(
                                    "span",
                                    {
                                        on: {
                                            click: () => {
                                                this.activeId = params.row.id
                                                this.addChangeIden = false
                                                this.addChangeTitle = "修改问卷"
                                                this.changeActivity()
                                            },
                                        },
                                        style: {
                                            width: "65px",
                                            marginRight: "10px",
                                            textAlign: "center",
                                            display: this.buttonRoot == "1001" ? "" : "none",
                                            color: "#2d8cf0",
                                            cursor: "pointer",
                                            userSelect: "none",
                                        },
                                    },
                                    "修改" //1001
                                ),
                                h(
                                    "span",
                                    {
                                        on: {
                                            click: () => {
                                                this.infoCode = params.row.code
                                                this.qrOCdeName = params.row.title
                                                this.$Message.loading({
                                                    background: true,
                                                    content: "正在加载数据，请稍等...",
                                                    duration: 0,
                                                })
                                                this.signInObj = {
                                                    id: params.row.id,
                                                    businessType: params.row.businessType,
                                                    code: params.row.code,
                                                }
                                                this.$get("/old/api/pc/information/getInformationScopeLevel", {
                                                    informId: params.row.id,
                                                })
                                                    .then(res => {
                                                        if (res.code == 200) {
                                                            this.scopeStatus = res.data
                                                            this.getOfficialScopeList(params.row.id, "2", "44")
                                                        } else {
                                                            this.$Message.destroy()
                                                            this.$Message.error({
                                                                background: true,
                                                                content: res.desc,
                                                            })
                                                        }
                                                    })
                                                    .catch(err => {
                                                        console.log(err)
                                                        this.$Message.destroy()
                                                        this.$Message.error({
                                                            background: true,
                                                            content: "获取数据失败，请联系管理员处理",
                                                        })
                                                    })
                                            },
                                        },
                                        style: {
                                            width: "65px",
                                            marginRight: "10px",
                                            textAlign: "center",
                                            color: "#2d8cf0",
                                            cursor: "pointer",
                                            userSelect: "none",
                                        },
                                    },
                                    "二维码" //1001
                                ),
                                h(
                                    "span",
                                    {
                                        on: {
                                            click: () => {
                                                this.activeId = params.row.id
                                                this.publishTitle = "审核问卷"
                                                this.infoStatus = params.row.status
                                                this.publishButtonName = "通过"
                                                this.publishStatus = true

                                                this.publicRecommend = ""
                                                this.publicRecommend = params.row.recommend

                                                this.orgCode = params.row.orgCode
                                            },
                                        },
                                        style: {
                                            width: "65px",
                                            marginRight: "10px",
                                            textAlign: "center",
                                            display: this.buttonRoot == "1002" && params.row.status == "待审核" ? "" : "none",
                                            color: "#2d8cf0",
                                            cursor: "pointer",
                                            userSelect: "none",
                                        },
                                    },
                                    "审核" //1002
                                ),
                                h(
                                    "span",
                                    {
                                        on: {
                                            click: () => {
                                                this.publishTitle = "发布问卷"
                                                this.activeId = params.row.id
                                                this.infoStatus = params.row.status
                                                this.publishButtonName = "发布"
                                                this.publishStatus = true
                                                this.publicRecommend = ""
                                                this.publicRecommend = params.row.recommend

                                                this.orgCode = params.row.orgCode
                                            },
                                        },
                                        style: {
                                            width: "65px",
                                            marginRight: "10px",
                                            textAlign: "center",
                                            display: this.buttonRoot == "1003" && params.row.status == "待发布" ? "" : "none",
                                            color: "#2d8cf0",
                                            cursor: "pointer",
                                            userSelect: "none",
                                        },
                                    },
                                    "发布" //1003
                                ),
                                h(
                                    "Dropdown",
                                    {
                                        props: {
                                            transfer: true,
                                        },
                                    },
                                    [
                                        h(
                                            "span",
                                            {
                                                // class: "operation-title",
                                                style: {
                                                    color: "#2d8cf0",
                                                    cursor: "pointer",
                                                    userSelect: "none",
                                                    position: "relative",
                                                    display: "flex",
                                                },
                                                on: {
                                                    click: async () => {
                                                        this.$Message.loading({
                                                            background: true,
                                                            content: "正在加载，请稍等...",
                                                        })
                                                        await this.$get("/info/api/pc/information/v2/queryById", {
                                                            infoId: params.row.id,
                                                            businessType: params.row.businessType,
                                                            functionType: params.row.functionType,
                                                            oemCode: parent.vue.oemInfo.oemCode,
                                                            operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                                                            orgCode: parent.vue.loginInfo.userinfo.orgCode,
                                                            terminal: 2,
                                                        }).then(res => {
                                                            this.$Message.destroy()
                                                            if (res.code == 200) {
                                                                this.caozuoInfo = res.data
                                                                if (res.data) {
                                                                    let list1 = []
                                                                    let list2 = []
                                                                    if (res.data.status != "6") {
                                                                        list1.push({ name: "归档", type: "1" })
                                                                    }
                                                                    if (res.data.status == "4") {
                                                                        list1.push({ name: "过期", type: "2" })
                                                                    }
                                                                    if (this.buttonRoot == "1001") {
                                                                        list1.push({ name: "复制", type: "3" })
                                                                    }
                                                                    list1.push(
                                                                        { name: "删除", type: "4" },
                                                                        { name: "更换栏目", type: "5" },

                                                                        { name: "更新公布时间", type: "23" }
                                                                    )
                                                                    if (parent.vue.loginInfo.userinfo.master) {
                                                                        list2.push({ name: "用户操作记录", type: "10" })
                                                                    }
                                                                    if (this.isManager == "1") {
                                                                        list1.push({ name: "设为模板", type: "6" })
                                                                    }
                                                                    if (res.data.functionType == "24" || res.data.functionType == "25") {
                                                                        list2.push({ name: "采集信息", type: "15" })
                                                                    }
                                                                    if (res.data.status == 4 || res.data.status == 5) {
                                                                        list1.push({ name: "关联数据", type: "7" })
                                                                        if (res.data.functionType == "17") {
                                                                            list1.push(
                                                                                { name: "答题记录", type: "11" },
                                                                                { name: "中奖名单", type: "12" },
                                                                                { name: "抽奖名单", type: "13" },
                                                                                { name: "排行榜", type: "14" }
                                                                            )
                                                                        }
                                                                        // if (res.data.whetherDynamicForm == "1") {
                                                                        // if (res.data.functionType == "24") {
                                                                        //     // if (res.data.appointmentVo.appointmentType != 1) {
                                                                        //         list1.push({ name: "采集信息", type: "15" })
                                                                        //     }
                                                                        // }
                                                                        // }
                                                                        if (res.data.functionType == "24") {
                                                                            if (res.data.appointmentVo.appointmentType == 3 && res.data.status == 4 && res.data.functionType == 24) {
                                                                                list1.push({ name: "报名时段管理", type: "18" })
                                                                            }
                                                                        }

                                                                        // if (res.data.functionType == "25") {
                                                                        //     list1.push({ name: "采集信息", type: "15" })
                                                                        // }
                                                                        if (res.data.prizeVos && res.data.prizeVos.length && res.data.functionType == "24") {
                                                                            list1.push({ name: "中奖名单", type: "12" })
                                                                        }
                                                                        if (res.data.functionType == "24") {
                                                                            if (res.data.functionType == "24" && res.data.appointmentVo.appointmentType == 4) {
                                                                                list1.push({ name: "排号记录", type: "21" })
                                                                            }
                                                                        }
                                                                    }
                                                                    if (res.data.functionType != "15") {
                                                                        list1.push({ name: "配置相关链接", type: "8" })
                                                                    }
                                                                    if (res.data.status == "4") {
                                                                        list1.push({ name: "拟制机构推送", type: "9" })
                                                                    }
                                                                    if (res.data.functionType == "24" || res.data.functionType == "25") {
                                                                        list1.push({ name: "通知记录", type: "16" })
                                                                        // if (res.data.status == 4 && res.data.functionType == "24") {
                                                                        // list1.push({ name: "报名记录", type: "19" })
                                                                        // }
                                                                    }
                                                                    if (res.data.appointmentVo && JSON.stringify(res.data.appointmentVo) != "{}" && res.data.appointmentVo.whetherSign == 1) {
                                                                        list1.push({ name: "签到码", type: "20" })
                                                                    }
                                                                    list2.push({ name: "畅联组应读已读记录", type: "22" })
                                                                    list2.push({ name: "指定人群应读已读记录", type: "24" })
                                                                    this.caozuoList = list1
                                                                    this.caojiList = list2
                                                                }

                                                                this.caozuoStatusFn(true)
                                                            } else {
                                                                this.$Message.error({
                                                                    content: "获取数据详情失败",
                                                                    background: true,
                                                                })
                                                                return
                                                            }
                                                            this.getContent()
                                                        })
                                                    },
                                                },
                                            },
                                            "操作"
                                        ),
                                    ]
                                ),
                            ]
                        )
                    },
                },
            ],
            tableData: [],
            loading: false,
            page: 1,
            total: 0,
            pageSize: 20,
            // 菜单数据
            menuCodeObj: {},
            //   新增/修改
            infoId: "",
            addChangeIden: false,
            addChangeTitle: "",
            addChangeStatus: false,
            cancelModalTip: false,

            // 查看
            seeStatus: false,

            // 三级审核
            buttonRoot: "",
            status: "",

            //   驳回/发布
            publishTitle: "",
            publishStatus: false,
            infoStatus: "",
            publishButtonName: "",

            // 公众号链接
            officialStatus: false,
            officialUrl: "",
            codeImgUrl: "",

            officialID: "",
            officialCity: "",
            officialCityList: [],
            officialRegion: "",
            officialRegionList: [],
            officialStree: "",
            officialStreeList: [],
            officialProject: [],
            officialProjectList: [],
            spinOfficialShow: false,
            signInObj: {},
            spinShow: false,
            // 相关链接
            aboutLinkStatus: false,
            infoCode: "",

            //排序定时器
            timer: null,
            //推荐/排序
            recommend: "",
            infoId: "",
            sortNum: "",

            // zhw新增
            ChangeColumn: false,
            cancelModelTip: false,
            changeRow: {},
            // 级联选择器的数据
            cascaderData: [],
            cascadValue: [],

            // 用户操作记录
            userChooseTle: false,
            cancelModelUser: false,
            modelTitle: "", //用户操作纪律弹窗标题
            sendMessage: {
                businessType: "",
                infoId: "",
            },
            isOpenUserMessage: false, //是否点击了用户操作按钮
            selectList: [],
            numFenxiStatus: false, //数据分析模态框
            infoIdLis: "",

            // 二维码
            qrOCdeName: "",
            qrCodeOrgName: "",
            codeImgUrl: "",
            changeVal: "",
            projectList: [],

            // 导出按钮弹窗
            exportExcelStatus: false,
            exportExcelTip: false,
            exportList: {
                id: "",
            },
            //excel导出弹窗  社区数据
            communityList: [],
            // 选择社区的code
            marital: "",
            isTrue: 0,

            // 对比分析
            contrastStatus: false,
            contrastInfoId: "",
            contrastShow: false,
            conFunctionType: "",
            conBusinessType: "",

            // publish的recommend按钮
            publicRecommend: "",

            // 短信链接
            noteUrl: "",
            noteCode: "",

            // 存储当前信息的orgCode
            orgCode: "",

            //模板
            templateStatus: false,
            formData: {},
            addType: "add",

            relationStatus: false,
            quesDate: {},
            showNum: 0,

            // 控制首页引用 范围回显，停留界面只允许当前第一次
            quoteNum: 0,

            // 设为模板按钮权限
            isManager: "",

            // 分享二维码图片列表
            qrimgList: ["/images/pic_hd1.png", "/images/pic_hd2.png", "/images/pic_hd3.png", "/images/pic_hd4.png", "/images/pic_hd5.png", "/images/pic_hd6.png"],
            qrimgNo: "1",
            qrimg: "/images/pic_hd1.png",

            // 上传图片
            action: "",
            file: "",
            uploadData: {},
            imageName: "",
            imagePath: "",
            // 裁剪组件的基础配置option
            option: {
                img: "", // 裁剪图片的地址
                info: true, // 裁剪框的大小信息
                outputSize: 0.8, // 裁剪生成图片的质量
                outputType: "jpg", // 裁剪生成图片的格式
                canScale: true, // 图片是否允许滚轮缩放
                autoCrop: true, // 是否默认生成截图框
                autoCropWidth: 600, // 默认生成截图框宽度
                autoCropHeight: 500, // 默认生成截图框高度
                fixedBox: false, // 固定截图框大小 不允许改变
                fixed: true, // 是否开启截图框宽高固定比例
                fixedNumber: [400, 565], // 截图框的宽高比例
                full: true, // 是否输出原图比例的截图
                canMoveBox: true, // 截图框能否拖动
                original: false, // 上传图片按照原始比例渲染
                centerBox: true, // 截图框是否被限制在图片里面
                infoTrue: false, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
            },
            cropperStatus: false,

            //推送
            pushStatus: false,
            pushObj: {},

            status: "",
            status1: 0,
            status2: 0,
            status3: 0,

            // 更换栏目
            synchroniZationStatus: false,
            synchroniZationStatusTip: false,
            synchronId: "",

            textActive: false,

            caozuoInfo: {},
            caozuoStatus: false,
            caozuoList: [],
            caojiList: [],

            channelList: [
                { label: "0", value: "全部", select: "" },
                { label: "1", value: "TV端", select: "" },
                { label: "3", value: "APP端", select: "" },
                { label: "4", value: "小程序", select: "" },
            ],

            setContent: {
                terminal: [],
                recommend: "",
                contactMobile: "",
                pushType: [],
                groupNames: "",
                channels: "",
                tipsCodeList: [],
            },
            groupStatus: false,

            typeList: [],

            showImage: true,
            defaultListIcon: [],
            imageDesc: "",

            sendGroupStatus: false,
            grouptalbeColumns: [
                {
                    type: "selection",
                    width: 60,
                    align: "center",
                },
                {
                    title: "群名称",
                    key: "groupName",
                    width: 170,
                    align: "center",
                },
                {
                    title: "群成员数",
                    key: "memberCount",
                    width: 170,
                    align: "center",
                },
                {
                    title: "群类型",
                    key: "groupTypeName",
                    align: "center",
                    width: 170,
                },
            ],
            grouptableData: [],
            grouppage: 1,
            grouptotal: 0,
            grouploading: false,
            grouppageSize: 20,

            groupList: [],
            sendGroup: {
                pushChannel: "",
            },

            coreData: {
                custNum: 0,
                userNum: 0,
                viewNum: 0,
                registerNum: 0,
            },

            rightList: [],
            groupSelect: [],
            removeRightList: [],

            selectList2: [],

            saveLoading: false,
        }
    },
    methods: {
        pushToRightList() {
            if (this.selectList2.length == 0) {
                return this.$Message.warning({
                    content: "请勾选数据",
                    background: true,
                })
            }
            let data = []
            this.selectList2.map((el, index) => {
                if (!this.rightList.find(item => item.groupId == el.groupId)) {
                    data.push(this.selectList2[index])
                }
            })
            this.rightList.push(...data)
            this.$refs.lftable.$refs.lftable.selectAll(false)
        },
        // 选中右侧
        selectRightList(e, index) {
            if (e.target.parentNode.classList.contains("active")) {
                e.target.parentNode.classList.remove("active")
                this.removeRightList.splice(index, 1)
            } else {
                e.target.parentNode.classList.add("active")
                this.removeRightList.push(this.rightList[index])
            }
        },
        delRightList() {
            if (this.removeRightList.length == 0) {
                this.$Message.error({
                    background: true,
                    content: "请从已选择列表中选中需要删除群",
                })
                return
            }
            let data = []
            this.rightList.map((el, index) => {
                if (!this.removeRightList.find(item => item.groupId == el.groupId)) {
                    data.push(this.rightList[index])
                }
            })
            this.rightList = []
            setTimeout(() => {
                this.rightList = data
            }, 10)
            this.removeRightList = []
        },
        clearRightList() {
            this.rightList = []
        },
        grouptableSelect(val) {
            this.selectList2 = val
        },
        // 群分页
        grouphadlePageSize(val) {
            this.grouppage = val.page
            this.grouppageSize = val.pageSize
            if (this.sendGroup.pushChannel && this.sendGroup.pushChannel == "7") return
            if (this.sendGroup.pushChannel && this.sendGroup.pushChannel == "10") {
                this.selectAppByOrgCode()
                return
            }
            this.getGroupList()
        },

        // 关闭推送
        changeGroupStatus2(val) {
            this.sendGroupStatus = val
        },
        // 保存按钮
        saveSendGroup() {
            if (!this.sendGroup.pushChannel || this.sendGroup.pushChannel == "") {
                this.$Message.warning({
                    content: "请选择推送对象设置",
                    background: true,
                })
                return
            }
            this.$Modal.confirm({
                title: "温馨提示",
                content: "<p>是否进行精准推送</p>",
                onOk: () => {
                    var List = []
                    this.groupSelect = this.rightList
                    if (this.sendGroup.pushChannel == "3" || this.sendGroup.pushChannel == "7" || this.sendGroup.pushChannel == "10") {
                        if (!this.groupSelect || this.groupSelect.length == 0) {
                            this.$Message.warning({
                                content: this.sendGroup.pushChannel == "3" ? "请选择群" : "请选择报表",
                                background: true,
                            })
                            return
                        } else {
                            this.groupSelect.map(item => {
                                List.push(item.groupId)
                            })
                        }
                    }
                    this.$Message.loading({
                        content: "正在保存，请稍等",
                    })
                    this.$post(
                        "/gateway/syinfopublish/api/pc/information/v2/informationPUSH",
                        {
                            informationId: this.caozuoInfo.id,
                            communityCode: JSON.parse(sessionStorage.getItem("userDetail")).communityCode,
                            pushChannel: this.sendGroup.pushChannel,
                            groupList: this.sendGroup.pushChannel && (this.sendGroup.pushChannel == "3" || this.sendGroup.pushChannel == "7" || this.sendGroup.pushChannel == "10") ? List : [],
                            orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
                            custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                            staffName: parent.vue.loginInfo.userinfo.realName,
                            oemCode: parent.vue.oemInfo.oemCode,
                        },
                        { "Content-Type": "application/json" }
                    ).then(res => {
                        this.$Message.destroy()
                        if (res && res.code == 200) {
                            this.changeGroupStatus2(false)
                            this.$Message.success({
                                content: "操作成功",
                                background: true,
                            })
                            return
                        } else {
                            this.$Message.error({
                                content: "操作失败",
                                background: true,
                            })
                            return
                        }
                    })
                },
            })
        },
        // 精准推送
        changeOpenSendModal() {
            if (this.caozuoInfo.status != "4") {
                this.$Message.warning({
                    content: "已发布的内容才可精准推送给用户",
                    background: true,
                })
                return
            }
            this.sendGroup.pushChannel = ""
            this.groupSelect = []
            this.selectList2 = []
            this.rightList = []
            this.removeRightList = []
            this.grouppage = 1
            this.grouptotal = 0
            this.grouploading = false
            this.grouppageSize = 20
            this.grouptableData = []
            this.changeGroupStatus2(true)
            this.getGroupList()
        },
        // 推送记录
        jumpSendDetail() {
            this.$core.openLayerFrame({
                type: 2,
                title: "推送记录",
                content: `/page#/pushTable?informationId=${this.caozuoInfo.id}&title=${this.caozuoInfo.title}&code=${this.caozuoInfo.code}`,
                area: ["100%", "100%"],
            })
        },
        async getSelect() {
            await this.$get("/dictlist/api/lfcommon/pc/dict/selectDictDataList", {
                dictType: "CHAT_GROUP_TYPE",
                appCode: "",
            }).then(res => {
                let data = res.dataList
                this.groupList = []
                data.map(item => {
                    // if (item.groupType != "1") {
                    this.groupList.push({
                        value: item.dictKey,
                        label: item.dictValue,
                    })
                    // }
                })
            })
            // 我的管理员
            this.$.ajax({
                url: proxy["/gateway"].target + "/apps/oldpeople/api/pc/staff/getStaffCount",
                type: "GET",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: window.sessionStorage.getItem("accessToken"),
                },
                data: {
                    orgCode: parent.vue.loginInfo.userinfo.orgCode,
                },
                success: res => {
                    if (res && res.data && res.data) this.coreData.custNum = res.data
                },
            })
            // 我的用户数量
            this.$get("/gateway/sy-user/sy-user/pc/syuser/selectByOrgAnalyze", {
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
            }).then(res => {
                if (res && res.data && res.data.total) {
                    this.coreData.userNum = res.data.total
                }
            })
            // 社区用户注册用户
            try {
                this.$get("/statistic/api/symanage/pc/homepage/statHomePageInfo", {
                    orgCode: JSON.parse(sessionStorage.getItem("userDetail")).communityCode || "",
                }).then(res => {
                    if (res.code == 200) {
                        if (res.data.userRegister) {
                            this.coreData.registerNum = res.data.userRegister.total || 0
                        }
                        if (res.data.statRegister) {
                            this.coreData.viewNum = res.data.statRegister.total || 0
                        }
                    }
                })
            } catch (err) {}
        },
        // 获取群列表
        async getGroupList() {
            await this.getSelect()
            await this.$get("/sendmsg/pc/chat/groupCreate/queryPage", {
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                groupType: "",
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                oemCode: parent.vue.oemInfo.oemCode,
                pageSize: this.grouppageSize,
                page: this.grouppage,
            })
                .then(res => {
                    this.grouptalbeColumns = [
                        {
                            type: "selection",
                            width: 60,
                            align: "center",
                        },
                        {
                            title: "群名称",
                            key: "groupName",
                            width: 170,
                            align: "center",
                        },
                        {
                            title: "群成员数",
                            key: "memberCount",
                            width: 170,
                            align: "center",
                        },
                        {
                            title: "群类型",
                            key: "groupTypeName",
                            align: "center",
                            width: 170,
                        },
                    ]
                    if (res.code === "200") {
                        res.dataList.map((item, index, arr) => {
                            arr[index].gmtCreate = format(item.gmtCreate, "yyyy-MM-dd HH:mm:ss")
                            this.groupList.map(items => {
                                if (item.groupType == items.value) {
                                    item.groupTypeName = items.label
                                }
                            })
                            item.scopeName = item.scopeName.replace(/\//g, "")
                            let list = []
                            item.chatGroupManager.map(item => {
                                if (item.manager != null) {
                                    list.push(item.manager)
                                }
                            })
                            item.chatGroupManager = list.join(",")
                        })
                        this.grouptableData = res.dataList
                        this.grouptotal = res.maxCount
                        this.grouppageSize = res.pageSize
                    } else {
                        this.$Message.error({
                            background: true,
                            content: "数据获取失败",
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },

        async findReportByOrgCode() {
            await this.$get("/gateway/sy-user/sy-user/api/syuser/pc/userBaseStat/findReportByOrgCode", {
                orgCode: parent.vue.loginInfo.userinfo.communityCode,
            }).then(res => {
                this.grouptalbeColumns = [
                    {
                        type: "selection",
                        width: 60,
                        align: "center",
                    },
                    {
                        title: "报表名称",
                        key: "reportName",
                        width: 170,
                        align: "center",
                    },
                    {
                        title: "报表总人数",
                        key: "userTotal",
                        width: 170,
                        align: "center",
                    },
                    {
                        title: "报表激活人数",
                        key: "activeUser",
                        width: 170,
                        align: "center",
                    },
                ]
                if (res.code === "200") {
                    this.grouptableData = res.dataList.map(item => {
                        return {
                            ...item,
                            groupId: item.appId,
                            groupName: item.reportName,
                        }
                    })
                    this.grouptotal = res.maxCount
                    this.grouppageSize = res.pageSize
                } else {
                    this.$Message.error({
                        background: true,
                        content: "数据获取失败",
                    })
                }
            })
        },
        async selectAppByOrgCode() {
            await this.$get("/gateway/api/fastDev/AppForm/selectAppByOrgCode", {
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                pageSize: this.grouppageSize,
                page: this.grouppage,
                busiCategory: "DYTX",
            }).then(res => {
                this.grouptalbeColumns = [
                    {
                        type: "selection",
                        width: 60,
                        align: "center",
                    },
                    {
                        title: "报表名称",
                        key: "appName",
                        width: 170,
                        align: "center",
                    },
                    {
                        title: "报表总人数",
                        key: "dataAmount",
                        width: 170,
                        align: "center",
                    },
                    {
                        title: "报表激活人数",
                        key: "activeUserAmount",
                        width: 170,
                        align: "center",
                    },
                ]
                if (res.code === "200") {
                    this.grouptableData = res.dataList.map(item => {
                        return {
                            ...item,
                            ...item.appDataCountVo,
                            groupId: item.appId,
                            groupName: item.appName,
                        }
                    })
                    this.grouptotal = res.maxCount
                    this.grouppageSize = res.pageSize
                } else {
                    this.$Message.error({
                        background: true,
                        content: "数据获取失败",
                    })
                }
            })
        },
        async changePushChannel(val) {
            this.sendGroup.pushChannel = val
            this.groupSelect = []
            this.selectList = []
            this.rightList = []
            this.removeRightList = []
            this.grouppage = 1
            this.grouptotal = 0
            this.grouploading = false
            this.grouppageSize = 20
            this.grouptableData = []
            if (val && val == 3) {
                console.log("如果等于3")
                await this.getGroupList()
                return
            } else if (val && val == 7) {
                console.log("如果等于7")
                await this.findReportByOrgCode()
                return
            } else if (val && val == 10) {
                console.log("如果等于10")
                await this.selectAppByOrgCode()
            }
        },
        getContent() {
            try {
                this.setContent = {
                    terminal: (() => {
                        if (this.caozuoInfo.terminal && this.caozuoInfo.terminal != "") {
                            if (this.caozuoInfo.terminal.indexOf("0") > -1) {
                                console.log("如果是全选")
                                this.channelList.map(item => {
                                    item.select = true
                                })
                            } else {
                                this.channelList.map(item => {
                                    if (this.caozuoInfo.terminal.includes(item.label)) item.select = true
                                    else item.select = false
                                })
                                console.log("如果只是选择了几个", this.channelList)
                            }
                        }
                        return this.caozuoInfo.terminal
                    })(),
                    recommend: this.caozuoInfo.recommend && this.caozuoInfo.recommend ? this.caozuoInfo.recommend : "",
                    contactMobile: this.caozuoInfo.contactMobile && this.caozuoInfo.contactMobile != "" ? this.caozuoInfo.contactMobile : "",
                    pushType:
                        this.caozuoInfo.pushChannelVo && this.caozuoInfo.pushChannelVo.pushType && this.caozuoInfo.pushChannelVo.pushType != ""
                            ? this.caozuoInfo.pushChannelVo.pushType.split(",")
                            : [],
                    groupNames: (() => {
                        if (this.caozuoInfo.groupList && this.caozuoInfo.groupList.length != 0) {
                            let arr = []
                            this.caozuoInfo.groupList.map(item => {
                                arr.push(item.groupName)
                            })
                            this.$refs.checkGroup.rightList = this.caozuoInfo.groupList
                            return arr.join(",")
                        } else {
                            return ""
                        }
                    })(),
                    channels: this.caozuoInfo.pushChannelVo && this.caozuoInfo.pushChannelVo.channels ? this.caozuoInfo.pushChannelVo.channels : "",
                }
                this.imageDesc = ''
                this.defaultListIcon = []
                if (this.caozuoInfo.ruleVos && this.caozuoInfo.ruleVos.length) {
                    this.caozuoInfo.ruleVos.map(item => {
                        if (item.ruleCode == "SUCCESS_IMAGE") {
                            if (item.ruleValue && item.ruleValue != "") this.defaultListIcon = [{ name: "", url: item.ruleValue }]
                        }
                        if (item.ruleCode.indexOf("SUCCESS_DESC") > -1) {
                            this.imageDesc = item.ruleValue
                        }
                    })

                }
            } catch (err) {
                console.log("err", err)
                this.setContent = {
                    terminal: [],
                    recommend: "",
                    contactMobile: "",
                    pushType: [],
                    groupNames: "",
                    channels: "",
                    tipsCodeList: [],
                }
                this.channelList = [
                    { label: "0", value: "全部", select: "" },
                    { label: "1", value: "TV端", select: "" },
                    { label: "3", value: "APP端", select: "" },
                    { label: "4", value: "小程序", select: "" },
                ]
            }
        },
        async setSave() {
            let rules = this.caozuoInfo.ruleVos
            if (
                this.$refs.LiefengUploadIcon.uploadList &&
                this.$refs.LiefengUploadIcon.uploadList.length &&
                this.$refs.LiefengUploadIcon.uploadList[0] &&
                this.$refs.LiefengUploadIcon.uploadList[0].url != ""
            ) {
                let isFlag = false
                rules.map(item => {
                    if (item.ruleCode == "SUCCESS_IMAGE") {
                        isFlag = true
                        item.ruleValue = this.$refs.LiefengUploadIcon.uploadList[0].url
                    }
                })
                if (!isFlag) {
                    rules.push({
                        ruleCode: "SUCCESS_IMAGE",
                        ruleValue: this.$refs.LiefengUploadIcon.uploadList[0].url,
                    })
                }
            } else {
                let isFlag = false
                rules.map(item => {
                    if (item.ruleCode == "SUCCESS_IMAGE") {
                        isFlag = true
                        item.ruleValue = ""
                    }
                })
                if (!isFlag) {
                    rules.push({
                        ruleCode: "SUCCESS_IMAGE",
                        ruleValue: "",
                    })
                }
            }
            if (this.imageDesc && this.imageDesc != "") {
                let isFlag = false
                rules.map(item => {
                    if (item.ruleCode == "SUCCESS_DESC") {
                        isFlag = true
                        item.ruleValue = this.imageDesc
                    }
                })
                if (!isFlag) {
                    rules.push({
                        ruleCode: "SUCCESS_DESC",
                        ruleValue: this.imageDesc,
                    })
                }
            } else {
                let isFlag = false
                rules.map(item => {
                    if (item.ruleCode == "SUCCESS_DESC") {
                        isFlag = true
                        item.ruleValue = ""
                    }
                })
                if (!isFlag) {
                    rules.push({
                        ruleCode: "SUCCESS_DESC",
                        ruleValue: "",
                    })
                }
            }
            this.$Message.loading({
                content: "正在保存，请稍等",
            })
            this.sortChangeFn({
                ...this.setContent,
                pushType: this.setContent.pushType && this.setContent.pushType.length ? this.setContent.pushType.join(",") : "",
                id: this.caozuoInfo.id,
                sort: this.caozuoInfo.sort,
                status: this.caozuoInfo.status,
                terminalField: this.setContent.terminal.indexOf("0") != -1 ? "0" : typeof this.setContent.terminal == "Array" ? this.setContent.terminal.join(",") : this.setContent.terminal,
                ruleVos: rules,
            })
            this.$Message.destroy()
            this.caozuoStatusFn(false)
        },
        changeGroupStatus(val) {
            this.groupStatus = val
        },

        saveGroup() {
            let list = this.$refs.checkGroup.rightList
            if (list.length == 0) {
                this.$Message.warning({
                    content: "请选择群",
                    background: true,
                })
                return
            }
            let groupName = []
            let groupId = []
            list.map(item => {
                groupName.push(item.groupName)
                groupId.push(item.groupId)
            })
            this.setContent.groupNames = groupName.join(",")
            this.setContent.channels = groupId.join(",")
            this.groupStatus = false
        },
        changePushType(data) {
            if (data && data.length) {
                this.setContent.pushType = []
                this.setContent.pushType.push(data[data.length - 1])
            }
        },
        // 点击推送方式的checkbox按钮
        changeCheck(val, item) {
            if (item.label == "0") {
                if (val) {
                    this.channelList.map(item => {
                        item.select = true
                    })
                } else {
                    this.channelList.map(item => {
                        item.select = false
                    })
                }
            } else {
                if (val) {
                    let data = []
                    this.channelList.map(item => {
                        if (item.label != "0") {
                            if (item.select) {
                                data.push(item)
                            }
                        }
                    })
                    if (data.length == this.channelList.length - 1) {
                        this.channelList.map(item => {
                            item.select = true
                        })
                    }
                } else {
                    this.channelList.map(item => {
                        if (item.label == "0") {
                            item.select = false
                        }
                    })
                }
            }

            let data = []
            this.channelList.map(item => {
                if (item.label == "0") {
                    this.setContent.terminal = item.label
                } else {
                    if (item.select == true) {
                        data.push(item.label)
                    }
                }
            })
            if (data.length != 0) {
                if (data.length == this.channelList.length - 1) {
                    this.setContent.terminal = "0"
                } else {
                    this.setContent.terminal = data.join(",")
                }
            } else {
                this.setContent.terminal = ""
            }
            console.log(this.setContent.terminal)
        },
        changeItem(item) {
            let params = {
                row: this.caozuoInfo,
            }
            if (item) {
                let obj = {}
                this.caozuoStatusFn(false)
                switch (item.type) {
                    // 归档
                    case "1":
                        this.publishTitle = "归档活动"
                        this.activeId = params.row.id
                        this.infoStatus = "已过期"
                        this.publishButtonName = "归档"
                        this.publishStatus = true

                        this.orgCode = params.row.orgCode
                        return
                    // 过期
                    case "2":
                        this.publishTitle = "过期活动"
                        this.activeId = params.row.id
                        this.infoStatus = "已发布"
                        this.publishButtonName = "过期"
                        this.publishStatus = true

                        this.orgCode = params.row.orgCode
                        return
                    // 复制
                    case "3":
                        this.activeId = params.row.id
                        this.addChangeTitle = "新增问卷"
                        this.addChangeIden = true
                        this.addType = "copy"
                        this.changeActivity()
                        return
                    // 删除
                    case "4":
                        this.infoId = params.row.id
                        this.$Modal.confirm({
                            title: "温馨提示",
                            content: "<p>您正在删除当前信息，此操作不可逆，是否继续</p>",
                            onOk: () => {
                                this.$post("/info/api/pc/information/v2/deleteById", {
                                    infoId: this.infoId,
                                    operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                                    orgCode: parent.vue.loginInfo.userinfo.orgCode,
                                    oemCode: parent.vue.oemInfo.oemCode,
                                    businessType: this.menuCodeObj.businessType,
                                    functionType: this.menuCodeObj.functionType,
                                    terminal: "2",
                                }).then(res => {
                                    if (res.code == 200) {
                                        this.$Message.success({
                                            background: true,
                                            content: "删除成功",
                                        })
                                        this.hadlePageSize({
                                            page: this.page,
                                            pageSize: this.pageSize,
                                        })
                                    } else {
                                        this.$Message.success({
                                            background: true,
                                            content: res.desc,
                                        })
                                    }
                                })
                            },
                        })
                        return
                    // 更换栏目
                    case "5":
                        this.synchroniZationStatus = true
                        this.synchronId = params.row.id
                        return
                    // 设为模板
                    case "6":
                        this.setTemplate(params.row)
                        return
                    // 关联数据
                    case "7":
                        this.quesDate = params.row
                        this.relationStatus = true
                        this.showNum++
                        return
                    // 配置相关链接
                    case "8":
                        this.infoCode = params.row.code
                        this.infoId = params.row.id
                        this.aboutLinkStatus = true
                        return
                    // 拟制机构推送
                    case "9":
                        this.pushObj.informationId = params.row.id
                        this.pushObj.orgCode = params.row.orgCode
                        this.pushObj.functionType = params.row.functionType
                        this.pushObj.orgName = params.row.source
                        this.pushObj.title = params.row.title.substring(0, 20)
                        this.pushStatus = true
                        return
                    // 用户操作记录
                    case "10":
                        this.sendMessage = {
                            businessType: params.row.businessType,
                            infoId: params.row.id,
                        }
                        this.isOpenUserMessage = true
                        this.modelTitle = params.row.title
                        this.userChooseTle = true
                        return
                    // 答题记录
                    case "11":
                        return
                    // 中奖名单
                    case "12":
                        return
                    // 抽奖名单
                    case "13":
                        return
                    // 排行榜
                    case "14":
                        return
                    // 采集信息
                    case "15":
                        this.$router.push({
                            path: "/questionnairecollect",
                            query: {
                                id: params.row.id,
                                menuId: this.$core.getUrlParam("menuId"),
                            },
                        })
                        return
                    // 通知记录
                    case "16":
                        this.$router.push({
                            path: "/notificationrecord",
                            query: {
                                path: this.$route.path,
                                menuId: this.$route.query.menuId,
                                id: params.row.id,
                            },
                        })
                        return
                    // 对比分析
                    case "17":
                        this.contrastStatus = true
                        this.contrastShow = true
                        this.contrastInfoId = params.row.id
                        this.conBusinessType = params.row.businessType
                        this.conFunctionType = params.row.functionType
                        return
                    // 报名时段管理
                    case "18":
                        return
                    // 报名记录
                    case "19":
                        return
                    // 签到码
                    case "20":
                        return
                    // 排号记录
                    case "21":
                        return
                    // 畅联组应读已读记录
                    case "22":
                        this.$core.openLayerFrame({
                            type: 2,
                            title: "畅联组应读已读记录",
                            content: `/page#/statisticsread?i=${params.row.id}&menuId=${this.$route.query.menuId}`,
                            area: ["100%", "100%"],
                        })
                        return
                    // 更新公布时间
                    case "23":
                        let paramss = {
                            operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                            id: params.row.id, //当前信息类id
                            staffName: parent.vue.loginInfo.userinfo.realName,
                            businessType: params.row.businessType,
                            functionType: params.row.functionType || this.menuCodeObj.functionType || "",
                            oemCode: parent.vue.oemInfo.oemCode,
                            orgCode: parent.vue.loginInfo.userinfo.orgCode,
                            sort: params.row.sort,
                            recommend: params.row.recommend,
                            pushChannel: params.row.pushChannel || "",
                            status: params.row.status,
                        }

                        this.$post("/info/api/pc/information/v2/flushInformationTime", paramss)
                            .then(res => {
                                if (res.code == 200) {
                                    this.$Message.success({
                                        background: true,
                                        content: "更新时间成功",
                                    })
                                    return
                                } else {
                                    this.$Message.error({
                                        background: true,
                                        content: "更新时间失败",
                                    })
                                }
                            })
                            .catch(err => {
                                console.log(err)
                            })
                        return

                    // 指定人群应读已读记录
                    case "24":
                        this.$core.openLayerFrame({
                            type: 2,
                            title: "指定人群应读已读记录",
                            content: `/page#/statisticsread?i=${params.row.id}&menuId=${this.$route.query.menuId}&type=1`,
                            area: ["100%", "100%"],
                        })
                        return
                }
            }
        },
        // 操作按钮
        changeMore(id) {
            this.$core.openLayerFrame({
                type: 2,
                title: "微信群阅读情况",
                content: `/page#/wechatgroupchart?id=${id}`,
                area: ["100%", "100%"],
            })
        },
        // 关闭操作模态框
        caozuoStatusFn(status) {
            this.caozuoStatus = status
            this.showImage = false
            this.$nextTick(() => {
                this.showImage = true
            })
        },
        //左侧信息菜单
        selectMenu(name) {
            if (name == "7") {
                this.status = ""
            } else {
                this.status = name
            }
            this.page = 1
            this.hadlePageSize({
                page: this.page,
                pageSize: this.pageSize,
            })
            this.selectList = []
        },
        //获取信息状态的数量
        messageStatusCount() {
            this.$get("/datamsg/api/pc/information/v2/groupStatus", {
                dataScopeList: "",
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                oemCode: parent.vue.oemInfo.oemCode,
                operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                businessType: this.menuCodeObj.businessType,
                functionType: this.menuCodeObj.functionType,
                type: this.menuCodeObj.columnType,
                columnCode: this.menuCodeObj.columnCode,
            }).then(res => {
                if (res.code == 200) {
                    this.status1 = res.data.auditNumber
                    this.status2 = res.data.rejectNumber
                    this.status3 = res.data.waitNumber
                }
            })
        },
        // 清空区码
        clearRegion() {
            // this.officialRegionList = []
            this.officialRegion = ""
            this.officialStree = ""
            this.officialProject = []
            this.qrCodeOrgName = ""
            this.changeVal = ""
        },
        // 点击清空街道按钮
        clearStree() {
            this.officialStree = ""
            this.officialProject = []
            this.makeOffDisabled = false
            this.qrCodeOrgName = ""
            this.changeVal = ""
        },
        // 关闭分析模态框
        contrastClose() {
            this.contrastShow = false
            this.contrastStatus = false
        },
        // 对比分析模态框关闭事件
        contrastFn(status) {
            this.contrastStatus = status
            // this.contrastShow = false
        },

        selectCommunity(code) {
            this.marital = code
        },
        // 导出弹窗关闭模态框事件
        exportExcelStatusFn(status) {
            if (!status && this.exportExcelTip) {
                this.$Modal.confirm({
                    title: "温馨提示",
                    content: "您正在关闭此窗口，信息将不会保存，是否继续",
                    onOk: () => {
                        this.exportExcelStatus = status
                        this.exportExcelTip = false
                        this.marital = ""
                        this.exportList.id = ""
                    },
                })
            } else {
                this.exportExcelStatus = status
                this.exportExcelTip = false
            }
        },
        // 确认导出按钮
        canfirmExport() {
            if (this.marital == "") {
                this.$Message.warning({
                    content: "请选择社区",
                    background: true,
                })
                return
            } else {
                let data = {
                    id: this.exportList.id,
                    orgCode: this.marital,
                }
                this.exportBtn(data)
            }

            //  console.log(this.exportList.id)
            //  console.log(this.marital);
        },
        // 选择街道获取的数据
        changeProject(val) {
            this.projectList = val
            if (val && val.length > 0) {
                this.makeOffDisabled = true
            } else {
                this.makeOffDisabled = false
            }
        },
        // 点击下载宣传二维码
        mergeImage() {
            html2canvas(this.$refs.haibaoImg, { useCORS: true }).then(function (canvas) {
                let src = canvas.toDataURL()
                var image = new Image()

                // 解决跨域 Canvas 污染问题
                image.setAttribute("crossOrigin", "anonymous")
                image.onload = function () {
                    canvas.width = image.width
                    canvas.height = image.height
                    var context = canvas.getContext("2d")
                    context.drawImage(image, 0, 0, image.width, image.height)
                    var url = canvas.toDataURL("image/png", 1.0)
                    var a = document.createElement("a")
                    var event = new MouseEvent("click")
                    a.download = "公众号宣传二维码"
                    a.href = url
                    a.dispatchEvent(event)
                }
                image.src = src
            })
        },
        numFenxiFn(status) {
            this.numFenxiStatus = status
        },
        // 点击行为数据按钮
        openNumCount() {
            if (this.selectList.length == 0) {
                this.$Message.warning({
                    content: "请选择后进行点击",
                    background: true,
                })
                return
            } else {
                this.numFenxiStatus = true
                let List = []
                this.isTrue += 1
                this.selectList.map(item => {
                    List.push(item.id)
                })
                this.infoIdLis = List.join(",")
                console.log(this.infoIdLis)
            }
        },
        // 选择了什么
        tableSelect(val) {
            this.selectList = val
        },
        //排序
        sortChangeFn(obj) {
            let params = {
                ...obj,
                operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                id: this.infoId || obj.id, //当前信息类id
                staffName: parent.vue.loginInfo.userinfo.realName,
                businessType: this.menuCodeObj.businessType,
                functionType: this.menuCodeObj.functionType,
                oemCode: parent.vue.oemInfo.oemCode,
                orgCode: this.orgCode,
                sort: this.sortNum || obj.sort,
                recommend: this.recommend || obj.recommend,
                pushChannel: obj.pushChannel,
                status: obj.status,
                terminalField: obj.terminal,
                pushType: this.setContent.pushType && this.setContent.pushType.length ? this.setContent.pushType.join(",") : "",
                terminal: obj.terminal || "",
            }

            this.$post("/info/api/pc/information/v2/changeFieldJson", params, { "Content-type": "application/json" })
                .then(res => {
                    if (res.code == 200) {
                        this.hadlePageSize({
                            page: this.page,
                            pageSize: this.pageSize,
                        })
                        this.$Message.success({
                            background: true,
                            content: "修改成功",
                        })
                    } else {
                        this.$Message.error({
                            background: true,
                            content: "修改失败",
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },

        async changeStatusRow(data) {
            await this.$post(
                "/info/api/pc/information/v2/changeIndexJson",
                {
                    ...data,
                },
                { "Content-type": "application/json" }
            ).then(async res => {
                if (res.code == 200) {
                    this.hadlePageSize({
                        page: this.page,
                        pageSize: this.pageSize,
                    })
                    this.$Message.success({
                        background: true,
                        content: "修改成功",
                    })
                } else {
                    this.$Message.error({
                        background: true,
                        content: "修改失败",
                    })
                }
            })
        },
        // 查看
        seeStatusFn(status) {
            this.seeStatus = status
            this.closeModal()
        },
        // 查看跳去修改
        seeToChange() {
            this.$Message.loading({
                content: "正在加载数据，请稍等...",
                duration: 0,
            })
            this.seeStatus = false
            this.addChangeTitle = "修改问卷"
            this.addChangeStatus = true
        },
        // 相关链接
        aboutLinkStatusFn(status) {
            if (!status && this.cancelModalTip) {
                this.$Modal.confirm({
                    title: "温馨提示",
                    content: "您正在关闭此窗口，信息将不会保存，是否继续",
                    onOk: () => {
                        this.aboutLinkStatus = status
                    },
                })
            } else {
                this.aboutLinkStatus = status
            }
        },
        aboutLinkSave() {
            this.$refs.aboutLink.save()
        },
        // 公众号链接
        makeOfficialQRCode() {
            if (!this.officialCity) {
                this.$Message.error({
                    background: true,
                    content: "请至少选择一个范围",
                })
                return
            }
            // if (!this.officialStree) {
            // this.$Message.error({
            //   background: true,
            //   content: "只能生成街道或社区的签到码，请选择街道"
            // });
            // return;
            // } else if (this.scopeStatus == "5" && this.officialProject.length == 0) {
            //   this.$Message.error({
            //     background: true,
            //     content: "该活动只发布给社区，请选择社区"
            //   });
            //   return;
            // }
            this.spinShow = true
            this.linkMake = true
            this.showMake = false

            let params = {}
            if (this.officialCity && this.officialCity != "") {
                params = {
                    cityCode: this.officialCity,
                }
                this.officialCityList.map(item => {
                    if (item.orgCode == this.officialCity) {
                        this.qrCodeOrgName = item.orgName
                    }
                })
            }
            if (this.officialRegion && this.officialRegion != "") {
                params = {
                    regionCode: this.officialRegion,
                }
                this.officialRegionList.map(item => {
                    if (item.orgCode == this.officialRegion) {
                        this.qrCodeOrgName = item.orgName
                    }
                })
            }
            if (this.officialStree && this.officialStree != "") {
                params = {
                    streetCode: this.officialStree,
                }
                this.officialStreeList.map(item => {
                    if (item.orgCode == this.officialStree) {
                        this.qrCodeOrgName = item.orgName
                    }
                })
            }
            if (this.officialProject.length > 0) {
                this.qrCodeOrgName = ""
                let data = []
                params = {
                    communityList: (() => {
                        let result = []
                        this.officialProjectList.map(item => {
                            if (this.officialProject.indexOf(item.orgCode) != -1) {
                                result.push({
                                    projectCode: item.mappingCode,
                                    projectName: item.orgName,
                                })
                            }
                        })
                        return result
                    })(),
                }
                params.communityList.map(item => {
                    data.push(item.projectName)
                })
                this.qrCodeOrgName = data.join(",")
            }
            this.officialID = this.$core.randomString()
            this.$post(
                "/datamsg/api/pc/wxqr/createWxAppletQr",
                {
                    type: "ACTIVITY_SHARE",
                    value: JSON.stringify({
                        path: "pages/welcome/welcome",
                        redirectPath: "/mainTaskPages/modules/infoCollection/pages/detail/index",
                        params: {
                            ...params,
                            code: this.signInObj.code,
                            msgPushId: this.officialID,
                            // recommendId: this.signInObj.id
                        },
                    }),
                },
                { "Content-Type": "application/json" }
            ).then(res => {
                if (res.code == 200 && res.data) {
                    this.codeImgUrl = res.data.codeUrl
                    this.officialUrl = "pages/welcome/welcome?scene=" + res.data.code
                    this.noteCode = res.data.code
                    this.spinShow = false
                    // this.$post(
                    //   "/verifyapi/api/sytop/pc/urlLink/createUrlLink",
                    //   {
                    //     path:'pages/welcome/welcome',
                    //     query: `scene=${this.noteCode}`,
                    //   },
                    // ).then(res => {
                    //   if (res.code == 200 && res.data) {
                    //     this.noteUrl = res.data.link
                    //   }
                    // });
                }
            })
        },
        officialStatusFn(status) {
            if (!status) {
                this.spinOfficialShow = false
                this.codeImgUrl = ""
                this.officialUrl = ""
                this.officialID = ""
                this.scopeStatus = ""
                this.signInObj = ""
                this.officialRegion = ""
                this.officialRegionList = []
                this.officialStree = ""
                this.officialStreeList = []
                this.officialProject = []
                this.officialProjectList = []
                this.makeOffDisabled = false
            }
            this.officialStatus = status
        },
        getOfficialScopeList(informId, orgLevel, orgCodes) {
            if (!informId || (!orgCodes && (orgLevel == "4" || orgLevel == "5"))) return
            this.changeVal = orgCodes
            this.$get("/old/api/pc/information/getInformationScopeList", {
                informId: informId,
                orgLevel: orgLevel,
                orgCodes: orgCodes,
            })
                .then(res => {
                    if (res.code == 200 && res.dataList) {
                        if (orgLevel == "2") {
                            this.officialCityList = res.dataList
                            this.officialRegionList = []
                            this.officialRegion = ""
                            this.officialStreeList = []
                            this.officialStree = ""
                            this.officialProject = []
                            this.officialProjectList = []
                            if (res.dataList.length == 1) {
                                this.officialCity = res.dataList[0].orgCode
                                this.getOfficialScopeList(informId, "3", this.officialCity)
                            }
                        } else if (orgLevel == "3") {
                            if (res.dataList.length == 1) {
                                this.officialRegion = res.dataList[0].orgCode
                                this.getOfficialScopeList(informId, "4", this.officialRegion)
                            }
                            this.officialRegionList = res.dataList
                            this.officialStreeList = []
                            this.officialStree = ""
                            this.officialProject = []
                            this.officialProjectList = []
                        } else if (orgLevel == "4") {
                            if (res.dataList.length == 1) {
                                this.officialStree = res.dataList[0].orgCode
                                this.getOfficialScopeList(informId, "5", this.officialStree)
                            }
                            this.officialStreeList = res.dataList

                            this.officialProject = []
                            this.officialProjectList = []
                        } else {
                            if (res.dataList.length == 1) {
                                this.officialProject.push(res.dataList[0].orgCode)
                            }
                            this.officialProjectList = res.dataList
                        }
                        this.$Message.destroy()
                        this.officialStatus = true
                    } else {
                        this.$Message.error({
                            background: true,
                            content: res.desc,
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.$Message.error({
                        background: true,
                        content: "获取数据失败，请联系管理员处理",
                    })
                })
        },
        downloadImgCode() {
            var image = new Image()
            // 解决跨域 Canvas 污染问题
            image.setAttribute("crossOrigin", "anonymous")
            image.onload = function () {
                var canvas = document.createElement("canvas")
                canvas.width = image.width
                canvas.height = image.height
                var context = canvas.getContext("2d")
                context.drawImage(image, 0, 0, image.width, image.height)
                var url = canvas.toDataURL("image/png") //得到图片的base64编码数据
                var a = document.createElement("a") // 生成一个a元素
                var event = new MouseEvent("click") // 创建一个单击事件
                a.download = "公众号推广二维码"
                a.href = url // 将生成的URL设置为a.href属性
                a.dispatchEvent(event) // 触发a的单击事件
            }
            image.src = this.codeImgUrl
        },
        copyShareCode() {
            var oInput = document.createElement("input")
            oInput.value = this.officialUrl
            document.body.appendChild(oInput)
            oInput.select()
            document.execCommand("Copy")
            oInput.className = "oInput"
            oInput.style.display = "none"
            document.body.removeChild(oInput)
            this.$Message.success({
                background: true,
                content: "复制链接成功！",
            })
        },
        copyOfficialID() {
            var oInput = document.createElement("input")
            oInput.value = this.officialID
            document.body.appendChild(oInput)
            oInput.select()
            document.execCommand("Copy")
            oInput.className = "oInput"
            oInput.style.display = "none"
            document.body.removeChild(oInput)
            this.$Message.success({
                background: true,
                content: "复制渠道ID成功！",
            })
        },
        // 点击复制短信链接
        copyNoteUrl() {
            var oInput = document.createElement("input")
            oInput.value = this.noteUrl
            document.body.appendChild(oInput)
            oInput.select()
            document.execCommand("Copy")
            oInput.className = "oInput"
            oInput.style.display = "none"
            document.body.removeChild(oInput)
            this.$Message.success({
                background: true,
                content: "复制链接成功！",
            })
        },
        // 发布/审核
        publishStatusFn(status) {
            if (this.$route.query.id && this.$route.query.menuId && this.$route.query.status && !status) {
                if (this.$route.query.status == 1 || this.$route.query.status == 3 || this.$route.query.status == 4) {
                    parent.layer.closeAll()
                    return
                }
                var index = parent.layer.getFrameIndex(window.name) //先得到当前iframe层的索引
                parent.layer.close(index) //再执行关闭
            } else {
                this.publishStatus = status
            }
        },
        reject() {
            this.$refs.publish.reject()
        },
        publish() {
            this.$refs.publish.publish()
        },
        // 通过并发布按钮
        passAndSend() {
            this.$refs.publish.passAndSend()
        },
        // 修改
        changeActivity() {
            this.$Message.loading({
                content: "正在加载数据，请稍等...",
                duration: 0,
            })
            this.addChangeStatus = true
        },
        reset() {
            this.searchData = { dataScopeList: [] }
            this.hadlePageSize({
                page: this.page,
                pageSize: this.pageSize,
            })
        },
        search() {
            this.hadlePageSize({
                page: 1,
                pageSize: this.pageSize,
            })
        },
        // 新增/修改
        closeModal() {
            if (this.$route.query.columnCode && this.$route.query.functionType) {
                var index = parent.layer.getFrameIndex(window.name)
                if (index) {
                    //先得到当前iframe层的索引
                    parent.layer.close(index) //再执行关闭
                } else {
                    this.$router.push(`allIndex?menuId=${this.$route.query.menuId || ""}`)
                }
                return
            }
        },
        // 新增/修改
        addChangeStatusFn(status) {
            if (this.$route.query.id && this.$route.query.menuId && this.$route.query.status && !status) {
                var index = parent.layer.getFrameIndex(window.name) //先得到当前iframe层的索引
                if (this.$route.query.status == 6) {
                    this.closeModal()
                } else {
                    parent.layer.close(index) //再执行关闭
                }
            } else if (!status && this.cancelModalTip) {
                this.$Modal.confirm({
                    title: "温馨提示",
                    content: "您正在关闭此窗口，信息将不会保存，是否继续",
                    onOk: () => {
                        this.addChangeStatus = status
                        this.addType = ""
                        this.quoteNum = 1
                        this.formData = {
                            terminal: ["1", "3", "4"],
                            time: (() => {
                                const end = new Date()
                                const start = new Date()
                                end.setTime(start.getTime() + 3600 * 1000 * 24 * 365 * 5)
                                return [start, end]
                            })(),
                            source: parent.vue.loginInfo.userinfo.orgName,
                            recommend: "0", //是否推荐,
                            pushChannel: false, //小程序推送通知
                        }
                        this.closeModal()
                    },
                })
            } else {
                this.addChangeStatus = status
                if (this.$route.query.status == 6 || this.$route.query.templateId) return
                this.closeModal()
            }
        },
        contentFn() {
            //预览文本
            this.$refs.addChange.contentFn()
        },
        contentFn1() {
            //预览文本
            this.$refs.publish1.contentFn()
        },
        async save() {
            this.saveLoading = true
            try {
                this.quoteNum = 1
                this.saveDisabled = true
                await this.$refs.addChange.save()
                this.saveLoading = false
            } catch (err) {
                this.saveLoading = false
            }
        },
        // 新增修改,配置相关链接成功后执行
        saveSuccess() {
            if (this.$route.query.status && (this.$route.query.status == 1 || this.$route.query.status == 3 || this.$route.query.status == 4)) {
                console.log("发布成功之后进这里")
                parent.layer.closeAll()
                return
            }
            this.templateStatus = false
            this.cancelModalTip = false
            this.addChangeStatus = false
            this.publishStatus = false
            this.aboutLinkStatus = false
            this.hadlePageSize({
                page: this.page,
                pageSize: this.pageSize,
            })
        },
        hadlePageSize(obj) {
            this.loading = true
            this.$get(
                // "/info/api/pc/information/v2/queryDataPage",
                "/datamsg/api/pc/information/v2/queryDataPage",
                {
                    ...this.searchData,
                    dataScopeList: this.searchData.dataScopeList.length > 0 ? this.searchData.dataScopeList[this.searchData.dataScopeList.length - 1] : "",
                    oemCode: parent.vue.oemInfo.oemCode,
                    operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                    businessType: this.menuCodeObj.businessType,
                    functionType: this.menuCodeObj.functionType,
                    columnCode: this.menuCodeObj.columnCode,
                    page: obj.page,
                    pageSize: obj.pageSize,
                    orgCode: parent.vue.loginInfo.userinfo.orgCode,
                    terminal: "2",
                    status: this.status,
                }
            )
                .then(res => {
                    //获取信息状态的数量
                    this.messageStatusCount()
                    this.loading = false
                    if (res.code == 200 && res.dataList) {
                        this.tableData = res.dataList.map(item => {
                            return {
                                orgCode: item.orgCode,
                                businessType: item.businessType,
                                title: item.title,
                                code: item.code,
                                releaseScope: item.releaseScope,
                                startTime: this.$core.formatDate(new Date(item.startTime), "yyyy-MM-dd hh:mm:ss"),
                                endTime: this.$core.formatDate(new Date(item.endTime), "yyyy-MM-dd hh:mm:ss"),
                                id: item.id,
                                sort: Number(item.sort),
                                source: item.source,
                                recommend: item.recommend,
                                status: (() => {
                                    switch (item.status) {
                                        case "1":
                                            return "待审核"
                                        case "2":
                                            return "审核不通过"
                                        case "3":
                                            return "待发布"
                                        case "4":
                                            return "已发布"
                                        case "5":
                                            return "已过期"
                                        case "6":
                                            return "已归档"
                                    }
                                })(),
                                authentication: item.ruleVos
                                    ? (() => {
                                          let str = ""
                                          item.ruleVos.map(subItem => {
                                              if (subItem.ruleCode == "authentication") {
                                                  if (subItem.ruleValue == "true") {
                                                      str = "是"
                                                  } else {
                                                      str = "否"
                                                  }
                                              }
                                          })
                                          return str
                                      })()
                                    : "",
                                createName: item.operationVo ? item.operationVo.createName : "",
                                functionType: item.functionType ? item.functionType : "",
                                terminal: item.terminal,
                            }
                        })
                        this.page = res.currentPage
                        this.pageSize = res.pageSize
                        this.total = res.maxCount
                    } else {
                        this.$Message.error({
                            background: true,
                            content: res.desc,
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.loading = false
                    this.$Message.error({
                        background: true,
                        content: "获取数据失败，请重试",
                    })
                })
        },
        // 获取区
        getPulishData(code, list) {
            this.$get("/old/api/pc/staff/selectCascadeDataScope", {
                orgCode: code,
                oemCode: parent.vue.oemInfo.oemCode,
                orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
            }).then(res => {
                if (res.code == 200) {
                    this.releaseScopeList = res.dataList.map(item => {
                        return {
                            value: item.orgPath,
                            label: item.orgName,
                            orgLevel: item.orgLevel,
                            loading: false,
                            children: [],
                        }
                    })
                }
            })
        },
        // 动态加载街道/社区
        loadCommunity(item, callback) {
            item.loading = true
            let arrCode = item.value.split("-")
            this.$get("/old/api/pc/staff/selectCascadeDataScope", {
                orgCode: arrCode[arrCode.length - 1],
                oemCode: parent.vue.oemInfo.oemCode,
                orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
            }).then(res => {
                if (res.code == 200) {
                    if (!item.index) {
                        item.children = res.dataList.map(item => {
                            return {
                                value: item.orgPath,
                                label: item.orgName,
                                orgLevel: item.orgLevel,
                                loading: false,
                                children: [],
                                index: 1,
                            }
                        })
                    } else {
                        item.children = res.dataList.map(item => {
                            return {
                                value: item.orgPath,
                                label: item.orgName,
                                orgLevel: item.orgLevel,
                                index: 1,
                            }
                        })
                    }
                    item.loading = false
                    callback()
                }
            })
        },
        // zhw新增
        // 更换栏目
        // 点击关闭模态框的方法
        ChangeColumnFn(status) {
            console.log("获取模态框状态", status)
            if (!status && this.cancelModelTip) {
                this.$Modal.confirm({
                    title: "温馨提示",
                    content: "<p>确认要关闭窗口吗？未保存的信息不会保存</p>",
                    onOk: () => {
                        this.ChangeColumn = status
                        this.cascadValue = []
                    },
                })
            } else {
                this.ChangeColumn = status
            }
        },
        // 更换栏目模态框保存按钮
        changeSave() {
            let menuId = ""
            if (this.cascadValue.length == 0) {
                this.$Message.warning({
                    content: "请选择后进行保存！",
                    background: true,
                })
                return
            } else {
                menuId = this.cascadValue.pop()
                console.log(menuId)
            }
            this.$post("/datamsg/api/pc/information/changeColumn", {
                businessType: this.changeRow.businessType,
                infoId: this.changeRow.id,
                adminType: parent.vue.loginInfo.userinfo.adminType,
                menuId: menuId,
            }).then(res => {
                if (res.code == 200) {
                    this.cancelModelTip = false
                    this.ChangeColumnFn(false)
                    this.hadlePageSize({
                        page: this.page,
                        pageSize: this.pageSize,
                    })
                    this.$Message.success({
                        content: "保存成功！",
                        background: true,
                    })
                    this.cascadValue = []
                } else {
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                }
            })
        },
        getColumnTree() {
            this.$post(
                "/datamsg/api/pc/information/getChangeColumnMenuTree",
                {
                    oemCode: parent.vue.oemInfo.oemCode,
                    orgCode: this.changeRow.orgCode,
                    staffId: parent.vue.loginInfo.userinfo.id,
                    adminType: parent.vue.loginInfo.userinfo.adminType,
                    businessType: this.changeRow.businessType,
                    infoId: this.changeRow.id,
                }
                // { "Content-Type": "application/json" }
            ).then(res => {
                if (res.code == 200) {
                    let data = res.dataList
                    this.cascaderData = this.forMatterTree(data)
                    console.log("递归修改value值后的结构树", this.cascaderData)
                } else {
                    this.$Message.error({
                        content: "获取数据失败",
                        background: true,
                    })
                }
            })
        },
        // 格式化树结构，给树结构添加上value值和label值
        forMatterTree(data) {
            for (var i = 0; i < data.length; i++) {
                data[i].label = data[i].title
                data[i].value = data[i].id
                if (data[i].children.length > 0 || !data[i].children) {
                    this.forMatterTree(data[i].children)
                }
            }
            return data
        },
        //用户操作关闭模态框操作
        UserChooseFn(status) {
            if (!status && this.cancelModelUser) {
                this.$Modal.confirm({
                    title: "温馨提示",
                    content: "<p>确认要关闭窗口吗？未保存的信息不会保存</p>",
                    onOk: () => {
                        this.userChooseTle = status
                    },
                })
            } else {
                this.userChooseTle = status
            }
        },
        // 用户操作记录保存
        userSave(status) {
            this.userChooseTle = status
            this.isOpenUserMessage = false
        },
        // 导出按钮
        exportBtn(data) {
            this.$post("/datamsg/api/pc/dynamicform/statExportHouseOption", {
                formType: data.id,
                orgCode: data.orgCode,
            }).then(res => {
                if (res && res.code == 200) {
                    if (res.data) {
                        if (res.data.slice(0, 5) == "http") {
                            res.data = res.data.replace("http", "https")
                        }
                    }

                    let link = document.createElement("a")
                    link.href = res.data
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                    this.$Message.destroy()
                    this.exportExcelStatus = false
                    this.exportExcelTip = false
                    this.marital = ""
                    this.exportList.id = ""
                } else {
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                }
            })
        },

        changeTemplateStatus(val) {
            this.templateStatus = val
            if (!val) {
                this.$refs.Templatetable.templateName = ""
            }
        },
        // 设为模板
        setTemplate(data) {
            this.$post("/info/api/pc/information/template/addInformationTemplate", {
                infoId: data.id,
                businessType: this.menuCodeObj.businessType,
                functionType: this.menuCodeObj.functionType,
                oemCode: parent.vue.oemInfo.oemCode,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                terminal: "2",
            }).then(res => {
                if (res.code == 200) {
                    this.$Message.success({
                        content: "操作成功",
                        background: true,
                    })
                } else {
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                }
            })
        },
        // 点击新增
        addQues() {
            this.templateStatus = true
            this.columnCode = this.menuCodeObj.columnCode
            this.$nextTick(() => {
                this.$refs.Templatetable.getList()
            })

            this.addChangeTitle = "新增问卷"
            this.activeId = ""
            this.addChangeIden = true
            // addChangeStatus = true;
        },
        setFormData(obj) {
            this.formData = obj
        },
        changeAddType(val) {
            console.log(val)
            this.addType = val
        },
        changeRelationStatus(val) {
            this.relationStatus = val
        },
        quoteData() {
            this.$nextTick(() => {
                this.$refs.addChange.quoteData()
            })
        },

        // 二维码背景选择
        changeQrimgNo(item, index) {
            this.qrimgNo = index + 1
            this.qrimg = `${item}`

            if (index >= 10) {
                this.textActive = true
            } else {
                this.textActive = false
            }
        },

        // 上传之前
        beforeUpload(file) {
            return this.$get("/datamsg/api/common/file/getWebPolicy?bucketName=liefeng").then(res => {
                if (res.code == 200) {
                    let now = this.$core.randomNumber()
                    //防止文件名有.
                    let suffixArr = file.name.split(".")
                    let suffix = "." + suffixArr[suffixArr.length - 1]
                    this.action = window.vue.getProxy()["/oss"].target
                    this.uploadData = {
                        name: now + suffix,
                        host: res.data.host,
                        key: res.data.key + now + suffix,
                        policy: res.data.encodedPolicy,
                        OSSAccessKeyId: res.data.accessId,
                        success_action_status: "200",
                        signature: res.data.postSignature,
                    }
                    this.imageName = now + suffix
                    this.imagePath = res.data.host + res.data.key + this.imageName
                }
            })
        },
        //图片上传成功
        imageSuccess(file) {
            if (file.status == "finished") {
                this.qrimgNo = ""
                this.qrimg = this.imagePath
                this.$Message["success"]({
                    background: true,
                    content: "图片上传成功！",
                })
            } else {
                this.$Message["error"]({
                    background: true,
                    content: "图片上传失败！",
                })
            }
        },
        // 图片格式错误
        imageError(file) {
            this.$Notice.warning({
                title: "上传文件格式错误！",
                desc: "文件 " + file.name + " 上传失败, 请上传jpg, jpeg, png或gif格式的图片！",
            })
        },
        // 图片上传进度
        imageProgress(event, file, fileList) {
            this.file = file
            event.target.onprogress = event => {
                let uploadPercent = parseFloat(((event.loaded / event.total) * 100).toFixed(2)) // 保留两位小数
                // 手动设置显示上传进度条 以及上传百分比
                this.file.showProgress = true
                this.file.percentage = uploadPercent
            }
        },
        // 图片移除
        imageRemove(file) {
            this.qrimg = ""
            this.file = {}
        },
        //取消裁剪
        cropperCancel(status) {
            this.cropperStatus = status
        },
        //打开裁剪
        async cropperFn() {
            if (!this.uploadData.host) {
                await this.$get("/datamsg/api/common/file/getWebPolicy?bucketName=liefeng").then(res => {
                    if (res.code == 200) {
                        let now = this.$core.randomNumber()
                        //防止文件名有.
                        let suffixArr = this.qrimg.split(".")
                        let suffix = "." + suffixArr[suffixArr.length - 1]
                        this.action = window.vue.getProxy()["/oss"].target
                        this.uploadData = {
                            name: now + suffix,
                            host: res.data.host,
                            key: res.data.key + now + suffix,
                            policy: res.data.encodedPolicy,
                            OSSAccessKeyId: res.data.accessId,
                            success_action_status: "200",
                            signature: res.data.postSignature,
                        }
                        this.imageName = now + suffix
                        this.imagePath = this.uploadData.host + this.uploadData.key
                    }
                })
            }
            let _this = this
            this.setAvatarBase64(this.qrimg, base64 => {
                _this.option.img = base64
            })
            this.cropperStatus = true
        },
        //确定裁剪
        cropperFinish() {
            this.$refs.cropper.getCropBlob(data => {
                this.$refs.imgUpload.clearFiles()
                let formData = new FormData()
                formData.append("name", this.uploadData.name)
                formData.append("host", this.uploadData.host)
                formData.append("key", this.uploadData.key)
                formData.append("policy", this.uploadData.policy)
                formData.append("OSSAccessKeyId", this.uploadData.OSSAccessKeyId)
                formData.append("success_action_status", this.uploadData.success_action_status)
                formData.append("signature", this.uploadData.signature)
                formData.append("file", data)
                this.$post("https://liefeng.oss-cn-shenzhen.aliyuncs.com", formData, {
                    "Content-Type": "multipart/form-data",
                }).then(res => {
                    this.$Message.success({
                        background: true,
                        content: "裁剪完成",
                    })
                    this.qrimg = ""
                    this.$nextTick(() => {
                        this.qrimg = this.imagePath + "?" + new Date().getTime()
                        this.cropperCancel(false)
                    })
                })
            })
        },
        // 设置图片base64
        setAvatarBase64(src, callback) {
            let _this = this
            let image = new Image()
            // 处理缓存
            image.src = src + "?v=" + Math.random()
            // 支持跨域图片
            image.crossOrigin = "*"
            image.onload = function () {
                let base64 = _this.transBase64FromImage(image)
                callback && callback(base64)
            }
        },
        // 将网络图片转换成base64格式
        transBase64FromImage(image) {
            let canvas = document.createElement("canvas")
            canvas.width = image.width
            canvas.height = image.height
            let ctx = canvas.getContext("2d")
            ctx.drawImage(image, 0, 0, image.width, image.height)
            // 可选其他值 image/jpeg
            return canvas.toDataURL("image/png")
        },

        // 推送窗口
        changePushStatus(val) {
            this.pushStatus = val
        },

        // 推送
        customPush() {
            if (!this.pushObj.title.trim()) {
                this.$Message.warning({
                    content: "请输入通知内容",
                    background: true,
                })
                return
            }
            this.$post("/gateway/syinfopublish/api/pc/information/v2/customPush", {
                ...this.pushObj,
                publish: true,
            }).then(res => {
                if (res.code == 200) {
                    this.$Message.success({
                        content: "推送成功",
                        background: true,
                    })
                    this.pushStatus = false
                } else {
                    this.$Message.error({
                        content: "推送失败",
                        background: true,
                    })
                }
            })
        },

        // 更换栏目确认按钮事件
        async saveSynchroniZation() {
            let list = this.$refs.Synchron.$refs.tree.getCheckedNodes().filter(item => item.columnCode)
            console.log("list", list)
            if (!list || !list.length) {
                this.$Message.warning({
                    content: "请勾选一个栏目",
                    background: true,
                })
            } else {
                this.$post(
                    "/info/api/pc/information/v2/changeColumn",
                    {
                        informationId: this.synchronId,
                        columnCode: list[list.length - 1].columnCode,
                        subColumnCode: list[list.length - 1].subColumnCode,
                    },
                    { "Content-Type": "application/json" }
                ).then(res => {
                    if (res.code == 200) {
                        this.$Message.success({
                            content: "更换栏目成功",
                            background: true,
                        })
                        this.synchroniZationStatus = false
                        this.synchroniZationStatusTip = false
                        this.hadlePageSize({
                            page: this.page,
                            pageSize: this.pageSize,
                        })
                    } else {
                        this.$Message.error({
                            content: "更换栏目失败",
                            background: true,
                        })
                    }
                })
            }
        },
        synchroniZationStatusFn(status) {
            if (!status && this.synchroniZationStatusTip) {
                this.$Modal.confirm({
                    title: "温馨提示",
                    content: "您正在关闭此窗口，信息将不会保存，是否继续",
                    onOk: () => {
                        this.synchroniZationStatus = status
                    },
                })
            } else {
                this.synchroniZationStatus = status
            }
        },
    },
    async created() {
        this.dimensionId = window.sessionStorage.getItem("dimensionId")
        if (this.$route.query.functionType && this.$route.query.columnCode && (!this.$route.query.templateId || this.$route.query.templateId == "")) {
            this.menuCodeObj = {
                functionType: this.$route.query.functionType,
                columnCode: this.$route.query.columnCode,
            }
            if (this.$route.query.status) {
                switch (this.$route.query.status) {
                    case "1":
                        this.publishTitle = "审核活动"
                        this.infoStatus = "待审核"
                        this.publishButtonName = "通过"
                        this.publishStatus = true
                        this.activeId = this.$route.query.id
                        break
                    case "2":
                        this.addChangeIden = false
                        this.publishTitle = "修改活动"
                        this.activeId = this.$route.query.id
                        this.changeActivity()
                        break
                    case "3":
                        this.publishTitle = "发布活动"
                        this.infoStatus = "待发布"
                        this.publishButtonName = "发布"
                        this.publishStatus = true
                        this.activeId = this.$route.query.id
                        break
                    case "4":
                        this.publishTitle = "过期活动"
                        this.infoStatus = "已发布"
                        this.publishButtonName = "过期"
                        this.publishStatus = true
                        this.activeId = this.$route.query.id
                        break
                    // 复制逻辑
                    case "5":
                        this.activeId = this.$route.query.id
                        this.addChangeTitle = "新增问卷"
                        this.addChangeIden = true
                        this.addType = "copy"
                        this.changeActivity()
                        break
                    // 引用
                    case "6":
                        this.setFormData(JSON.parse(window.sessionStorage.getItem("quoteData")))
                        this.changeAddType("quote")
                        this.addChangeStatusFn(true)
                        this.addChangeIden = true
                        this.addChangeTitle = "新增问卷"
                        this.activeId = ""
                        this.$nextTick(() => {
                            this.$refs.addChange.quoteData()
                        })
                        return
                    // 查看逻辑
                    case "7":
                        this.activeId = this.$route.query.id
                        this.seeStatus = true
                        break
                    // 归档
                    case "8":
                        this.publishTitle = "归档活动"
                        this.activeId = this.$route.query.id
                        this.infoStatus = 5
                        this.publishButtonName = "归档"
                        this.publishStatus = true
                        this.orgCode = this.$route.query.orgCode
                        return
                }
                return
            } else {
                // 新增逻辑
                this.addChangeTitle = "新增问卷"
                this.addChangeIden = true
                this.addType = "add"
                this.addChangeStatus = true
                this.setFormData({
                    terminal: ["1", "3", "4"],
                    time: (() => {
                        const end = new Date()
                        const start = new Date()
                        end.setTime(start.getTime() + 3600 * 1000 * 24 * 365 * 5)
                        return [start, end]
                    })(),
                    source: parent.vue.loginInfo.userinfo.orgName,
                    recommend: "0", //是否推荐,
                    pushChannel: false, //小程序推送通知
                })
                return
            }
        }
        let userButtonRoot = parent.vue.userButtonRoot
        this.isManager = userButtonRoot.includes("INFORMATION:TEMPLATE_ADD") ? "1" : "2"
        console.log(this.isManager)
        this.buttonRoot = userButtonRoot.includes("1001") ? "1001" : userButtonRoot.includes("1002") ? "1002" : userButtonRoot.includes("1003") ? "1003" : ""
        // this.buttonRoot = "1003";
        // this.status = this.buttonRoot == "1001" ? "2" : this.buttonRoot == "1002" ? "1" : this.buttonRoot == "1003" ? "3" : ""
        // this.activeMenu = this.status
        this.status = ""
        //默认选中第一项菜单
        this.activeMenu = "7"
        // 获取functionType/columnCode等
        await this.$get("/old/api/pc/menu/selectColumnByMenuCode", {
            menuCode: this.$core.getUrlParam("menuId"),
        }).then(res => {
            if (res.code == 200 && res.data) {
                this.menuCodeObj = res.data
            } else {
                this.$Message.error({
                    background: true,
                    content: res.desc,
                })
            }
        })
        if (this.$route.query.templateId) {
            await this.$get("/info/api/pc/information/template/selectInformationTemplate", {
                staffId: parent.vue.loginInfo.userinfo.id,
                oemCode: parent.vue.oemInfo.oemCode,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                templateId: this.$route.query.templateId,
            }).then(res => {
                if (res.code == 200) {
                    let obj = JSON.parse(res.data.dataJson)
                    this.setFormData(obj), this.changeAddType("quote")
                    this.addChangeStatusFn(true)
                    this.columnCode = this.menuCodeObj.columnCode
                    this.addChangeTitle = "新增问卷"
                    this.activeId = ""
                    this.addChangeIden = true
                    this.$nextTick(() => {
                        this.$refs.addChange.quoteData()
                    })
                } else {
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                }
            })
        }
        // 处理首页发布——跳审核发布
        if (this.$route.query.id && this.$route.query.menuId && this.$route.query.status) {
            switch (this.$route.query.status) {
                case "1":
                    this.publishTitle = "审核活动"
                    this.infoStatus = "待审核"
                    this.publishButtonName = "通过"
                    this.publishStatus = true
                    break
                case "2":
                    this.addChangeIden = false
                    this.publishTitle = "修改活动"
                    this.changeActivity()
                    break
                case "3":
                    this.publishTitle = "发布活动"
                    this.infoStatus = "待发布"
                    this.publishButtonName = "发布"
                    this.publishStatus = true

                    break
                case "4":
                    this.publishTitle = "过期活动"
                    this.infoStatus = "已发布"
                    this.publishButtonName = "过期"
                    this.publishStatus = true
                    break
            }
            this.activeId = this.$route.query.id
        }
        // 获取发布范围  releaseScopeList
        // 获取广州市的区
        this.getPulishData(4401)
        this.hadlePageSize({
            page: this.page,
            pageSize: this.pageSize,
        })
    },
    components: {
        LiefengContent,
        LiefengModal,
        LiefengTable,
        AddChange,
        Publish,
        AboutLink,
        CheckRecord,
        Analysis,
        contrast,
        Templatetable,
        Relation,
        Synchron,
        CheckGroup,
        LiefengUpload,
    },
    filters: {
        spliceTitle(option) {
            if (option.length > 22) {
                return (option = option.slice(0, 22) + "...")
            } else {
                return option
            }
        },
    },
}
</script>

<style lang="less">
.operation-title {
    &::after {
        content: ">";
        position: "absolute";
        transform: rotate(90deg);
        display: block;
        margin-left: 2px;
        font-size: 10px;
    }
}
</style>
<style scoped lang="less">
// /deep/#toolsbarRight {
//   width: 1000%;
//   .search {
//     width: 100%;
//     text-align: right;
//     .ivu-form-item {
//       margin-bottom: 0;
//     }
//   }
// }
/deep/.ivu-radio-group {
    width: 100% !important;
}
// 截图
.cropper-content {
    .cropper {
        width: 580px;
        height: 428px;
    }
}
.diyimg {
    position: absolute;
    right: 162px;
    bottom: 286px;
    width: 380px;
    height: 180px;
    .imglist {
        margin-top: 10px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 310px;

        img {
            width: 60px;
            margin-right: 10px;
            margin-bottom: 10px;
            cursor: pointer;
        }
        .active {
            // border: 2px solid red;
            box-shadow: 0 0 5px blue;
            box-sizing: border-box;
        }
    }
}
.diyimg-right {
    right: 131px;
}
.caozuo-modal {
    /deep/.ivu-modal-close {
        display: block;
    }
}
/deep/.ivu-modal-close {
    display: none;
}
.demo-spin-icon-load {
    animation: ani-demo-spin 1s linear infinite;
}
@keyframes ani-demo-spin {
    from {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.signInOut {
    /deep/ #modal_contentarea {
        // width: 1146px !important;
    }
    /deep/.ivu-modal-close {
        display: block;
    }
}
.qrdiv {
    position: relative;
    .qrcode {
        position: relative;
        width: 400px;
        height: 565px;
        background: url("/images/pic_hd1.jpg") no-repeat;
        background-size: 100% 100% !important;
        img {
            position: absolute;
            top: 235px;
            left: 90px;
            width: 220px;
        }

        .h1h3 {
            width: 260px;
            height: 119px;
            // background-color: #333333;
            // color: #ff0000;
            color: #333;
            // width: 100%;
            position: absolute;
            top: 75px;
            left: 70px;
            text-align: center;
            h1 {
                width: 59%;
                margin: 0 auto;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }
        }
        .fontsize1 {
            width: 100%;
            font-size: 26px;
            line-height: 119px;
            word-break: break-all;
        }
        .fontsize2 {
            width: 100%;
            box-sizing: border-box;
            font-size: 24px;
            line-height: 33px;
            word-break: break-all;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
        .fontsize3 {
            width: 100%;
            font-size: 20px;
            word-break: break-all;
            line-height: 30px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
        .fontactive {
            margin-top: 50px;
        }
        h1 {
            text-align: left;
            font-size: 26px;
            color: red;
            margin-bottom: 10px;
            line-height: 36px;
        }
        h3 {
            padding: 0 30px;
            text-align: center;
            font-size: 26px;
            color: #c62e23;
        }
        p {
            width: 100%;
            position: absolute;
            top: 201px;
            text-align: center;
            font-size: 15px;
            color: #666666;
        }
        span {
            width: 70%;
            position: absolute;
            text-align: center;
            top: 470px;
            font-size: 18px;
            color: #333;
            margin: 0 15%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .qrcode-antoer {
        width: 334px;
        height: 544px;
        .h1h3 {
            left: 35px;
        }
        img {
            left: 55px;
        }
    }
}
.ivu-poptip {
    background: black;
    padding: 0 4px;
    border-radius: 50%;
    .ivu-icon-md-help {
        color: #fff;
    }
}
/deep/.validate {
    &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #ed4014;
    }
}
.table-left {
    float: left;
    width: 160px;
    /deep/.ivu-menu {
        width: 160px !important;
    }
}
/deep/.ivu-tooltip-inner {
    width: 200px;
    white-space: normal;
}
/deep/.ivu-menu-item {
    z-index: 3;
}
.content-div {
    .module-item {
        margin: 10px 20px;
        box-sizing: border-box;

        > .title {
            font-size: 16px;
            font-weight: bold;
            line-height: 40px;
            color: #287aad;
        }
        .fun-btns {
            display: flex;
            flex-wrap: wrap;

            .btn-box {
                cursor: pointer;
                margin-right: 7px;
                margin-bottom: 7px;
                width: calc(100% / 4 - 7px + 7px / 4);
                border: 1px solid #f3f3f3;
                height: 35px;
                border-radius: 8px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                background: #f6f6f6;

                &:nth-child(4n) {
                    margin-right: 0;
                }
            }
        }
    }
    .import-div {
        display: flex;
        user-select: none;
        cursor: pointer;
        .left {
            width: 50%;
            border: 1px solid #ccc;
            margin-right: 10px;
            border-radius: 5px;
            padding: 10px 20px;
            height: 80px;
            text-align: center;
        }
        .right {
            width: 50%;
            border: 1px solid #ccc;
            margin-right: 10px;
            border-radius: 5px;
            padding: 10px 20px;
            height: 80px;
            text-align: center;
        }
        .desc {
            margin-top: 10px;
        }
        .blue {
            color: #287aad;
            font-weight: bold;
            margin-right: 5px;
        }
        .none {
            font-weight: bold;
            margin-left: 5px;
        }
        .red {
            margin-left: 20px;
            font-weight: bold;
            color: red;
        }
    }
}
.btn-view {
    display: flex;
    justify-content: center;
}
.caozuo-modal {
    /deep/ #modal_contentarea {
        max-height: 500px;
        overflow-y: scroll;
    }
}
.group-table {
    /deep/.ivu-table-wrapper {
        height: 500px !important;
    }
    /deep/.ivu-table-body {
        height: 460px !important;
    }
}
.container-div {
    height: 100%;
    display: flex;
    width: 100%;
    .tree-div {
        width: 200px;
        height: 100%;
        /deep/.ivu-tree {
            height: calc(100vh - 155px);
            overflow: auto;
        }
    }
    .center-table {
        width: calc(100% - 430px);
        margin-left: 20px;
        // border: 1px solid red;
    }
    .right-table {
        margin-left: auto;
        width: 410px;
        height: 500px;
        display: flex;
        .right-center {
            width: 100px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .right-right {
            width: 300px;
            border: 1px solid #dcdee2;
            overflow: auto;
        }
    }
    /deep/.active {
        background: #19be6b;
        color: #fff;
    }
}
</style>
